<template>
  <div class="field-wrapper">
    <div class="field-telephone-item">
      <div class="telephone-label">
        <img
          class="country-flag"
          src="https://www.countryflags.com/wp-content/uploads/india-flag-png-large.png"
        />
        <span class="country-code">+91</span>
      </div>
      <input
        type="tel"
        :name="field.name"
        pattern="[0-9]{10}"
        :placeholder="field.placeholder"
        :required="field.required"
        :readonly="field.readOnly != undefined ? field.readOnly : false"
        :class="{ error: field.hasError }"
        @focus="field.hasError = false"
        @input="fieldChanged"
        v-model="field.value"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "TelephoneField",
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  methods: {
    fieldChanged() {
      this.$emit("fieldChanged");
    },
  },
};
</script>

<style scoped lang="scss">
input[readonly] {
  cursor: default;
  border: 1px solid #e7e6ef;
}
</style>