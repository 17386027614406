<template>
  <div class="otp-wrapper">
    <input
      v-for="i in field.codeLength"
      :key="i"
      type="text"
      maxlength="1"
      @keyup="inputChanged($event, i)"
      @paste="onPaste($event, i)"
    />
  </div>
</template>
  
<script>
export default {
  name: "OTPField",
  props: {
    field: Object,
  },
  data() {
    return {};
  },
  methods: {
    inputChanged(e, i) {
      let char = String.fromCharCode(e.keyCode);

      //Check if the input is alphanumeric
      if (/^[A-Za-z0-9]+$/.test(char)) {
        e.target.value = char;
        this.constructOTP();

        //Check if focus should move to next element
        if (i < this.field.codeLength) e.target.nextElementSibling.focus();
      } else e.preventDefault();
    },

    onPaste(e, index) {
      //Get copied data
      let copiedData = e.clipboardData
        .getData("text/plain")
        .replace(/[^0-9a-z]/gi, "");

      //Get all inputs
      const inputs = Array.from(e.target.form.querySelectorAll("input"));

      //Paste the copied data
      for (let i = index, j = 0; i <= this.field.codeLength && j < copiedData.length; i++, j++) {
        inputs[i - 1].value = copiedData[j];
        inputs[i - 1].focus();
      }

      this.constructOTP();
    },

    constructOTP() {
      //Get all inputs
      let otpValue = "";
      const inputs = Array.from(document.querySelectorAll("input"));
      for (let i = 0; i < this.field.codeLength; i++) {
        otpValue += inputs[i].value.toUpperCase();
      }

      this.$emit("OTPChanged", this.field, otpValue);
    },
  },
};
</script>
  
<style scoped lang="scss">
.otp-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    flex: 1 1 0;
    text-align: center;
    margin: 0 0.5rem;
    font-size: $mediumFontSize;
    font-weight: $mediumFontWeight;
    text-transform: uppercase;
  }
}
</style>