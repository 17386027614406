<template>
  <Transition name="notification">
    <div v-if="show" class="notification-message" :class="status">
      <unicon v-if="status == 'success'" name="check"></unicon>
      <unicon
        v-else-if="status == 'error'"
        name="exclamation-triangle"
      ></unicon>

      <div class="info-wrapper">
        <h3 v-if="title">{{ title }}</h3>
        <p v-if="message">{{ message }}</p>
      </div>
    </div>
  </Transition>
</template>
  
<script>
export default {
  name: "NotificationMessage",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    status: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    message: {
      type: String,
      required: false,
    },
    additionalClass: {
      type: String,
      required: false,
    },
  },
  methods: {
    primaryEventHandler() {
      this.$emit("primaryEvent");
    },
    secondaryEventHandler() {
      this.$emit("secondaryEvent");
    },
  },
};
</script>
  
<style scoped lang="scss">
.notification-message {
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0.5rem;
  background-color: rgba($darkBlackColor, 0.85);
  border: 1px solid lighten($darkBlackColor, 10%);
  box-shadow: 0 0 0.7rem -0.15rem rgba($darkBoxShadowColor, 0.35);
  backdrop-filter: blur(15px);
  padding: 0.5rem 1rem;
  z-index: 10;

  .info-wrapper {
    h3 {
      font-size: $smallFontSize;
      font-weight: $mediumFontWeight;
      color: $whiteColor;
      margin-bottom: 0;
    }
    p {
      font-size: $smallerFontSize;
      color: $whiteColor;
      opacity: $lightOpacity;
    }
  }

  .unicon /deep/ {
    margin-right: 0.5rem;
    svg {
      fill: $whiteColor;
      height: auto;
      width: 1rem;
      margin-top: 0.15rem;
    }
  }

  &.error {
    background-color: rgba($redMessageTextColor, 0.85);
    border-color: lighten($redMessageTextColor, 15%);
  }
}
</style>