<template>
  <div class="dashboard-view">
    <div class="page-wrapper">
      <!--START: Page Content-->
      <div class="inbox-main page-content">
        <!--START: Inbox-->
        <div v-if="!chatSearched" class="inbox-messages-main">
          <AllTickets
            ref="allTickets"
            :tickets="tickets"
            :ticketIndex="ticketIndex"
            :totalCount="totalCount"
            :ticketsLoaded="showOptions.allTickets"
            @ticketSelected="ticketSelected"
            @getAllTickets="getAllTickets"
          ></AllTickets>

          <!--START: Line Loader-->
          <LineLoader :show="showOptions.lineLoader"></LineLoader>
          <!--END: Line Loader-->
        </div>

        <!--END: Inbox-->

        <!--START: Zero State -->
        <ZeroState
          v-if="showOptions.allTickets && tickets.length == 0"
          class="zero-state"
          :imageURL="zeroStateData.imageURL"
          :title="zeroStateData.title"
          :message="zeroStateData.message"
        ></ZeroState>
        <!--END: Zero State -->

        <!--START: Pre Loader-->
        <div v-else-if="lodash.isEmpty(ticket)" class="pre-loader-wrapper">
          <PreLoader :show="true" v-for="index in 2" :key="index"></PreLoader>
        </div>
        <!--END: Pre Loader-->

        <!--START: Selected Ticket-->
        <div v-else class="selected-ticket-main">
          <!--START: Selected Ticket-->
          <Ticket :ticket="ticket"></Ticket>
          <!--END: Selected Ticket-->
        </div>
        <!--END: Selected Ticket-->
      </div>
      <!--END: Page Content-->

      <!--START: Notification Message-->
      <NotificationMessage
        :show="status.show"
        :status="status.status"
        :title="status.title"
      ></NotificationMessage>
      <!--END: Notification Message-->
    </div>
  </div>
</template>
    
<script>
// Importing libraries
import _ from "lodash";

// Importing Services
import { TicketService } from "@/services";
import "external-svg-loader";

// Import Components
import ZeroState from "@/components/misc/ZeroState";
import AllTickets from "@/components/dashboard/inbox/AllTickets";
import Ticket from "@/components/dashboard/inbox/Ticket";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";
import PreLoader from "@/components/loaders/PreLoader";

export default {
  name: "Inbox",
  data() {
    return {
      lodash: _,
      showOptions: {
        chatHeaderShadow: false,
        lineLoader: false,
        allTickets: false,
        ticket: false,
      },
      chatSearched: false,
      ticket: {},
      tickets: [],
      totalCount: -1,
      ticketIndex: 0,
      pageNumber: 1,
      status: {
        show: false,
        status: "success",
        title: null,
      },

      zeroStateData: {
        imageURL: "holidays.png",
        title: "Start Using Macha AI on Zendesk",
        message:
          "Once your team starts using Macha on tickets, you'll see the generated answers, analysis and more",
      },
    };
  },
  props: {},
  watch: {},
  components: {
    ZeroState,
    AllTickets,
    Ticket,
    NotificationMessage,
    LineLoader,
    PreLoader,
  },
  computed: {},
  async created() {
    await this.initInbox();
  },

  methods: {
    // Load a new inbox
    async initInbox() {
      const { ticketID } = this.$route.query;

      if (!ticketID) {
        this.resetInbox();
        await this.getAllTickets();
        this.showOptions.allTickets = true;

        // Load the first ticket
        if (this.tickets.length) {
          const ticketID = this.tickets[0].ticketID;
          this.getTicket(ticketID);
        }
      } else {
        this.getTicket(ticketID);
      }
    },

    // Get a particular ticket
    async getTicket(ticketID) {
      this.ticket = {};
      const response = await TicketService.GetTicket({ ticketID });
      if (!response.hasError) {
        this.ticket = response.data;
      }
    },

    // Get all the tickets
    async getAllTickets() {
      if (this.totalCount == -1 || this.tickets.length <= this.totalCount) {
        this.showOptions.lineLoader = true;

        this.pageNumber += 1;
        const response = await TicketService.GetTickets({
          pageNumber: this.pageNumber,
        });
        const { tickets, totalCount } = response.data;
        this.totalCount = totalCount;
        this.tickets.push(...tickets);
      }

      this.showOptions.lineLoader = false;
    },

    // New ticket selected from inbox
    ticketSelected(ticket, index = 0) {
      this.getTicket(ticket.ticketID);
      this.ticketIndex = index;
    },

    // Reset the inbox
    resetInbox() {
      this.ticket = {};
      this.tickets.splice(0);
      this.pageNumber = 0;
      this.totalCount = -1;
    },
  },
};
</script>
    
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.inbox-main {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .inbox-messages-main {
    position: relative;
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-y: scroll;
    border-right: 1px solid $greyBorderColor;

    /deep/ .line-loader {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .selected-ticket-main {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100%;
    width: calc(80% - 1px);
  }
}

.page-menu {
  z-index: 5;
}

.pre-loader-wrapper {
  flex: 1;
  margin: 2rem;

  /deep/ {
    .buffer-category {
      height: 30vh;
    }
    .pre-loader:not(:first-child) {
      .buffer-title,
      .buffer-category {
        display: none;
      }
      .buffer-hero {
        margin-bottom: 0;
      }

      .buffer-line {
        width: auto;
      }
    }
  }
}

.zero-state {
  flex: 1;
  margin: 2rem;
  padding: 2rem 0;
  background-color: darken($whiteColor, 0.5%);
  border: 1px solid $greyBorderColor2;
  border-radius: 1rem;
}

/deep/ {
  .notification-message {
    position: fixed;
  }
}
</style>