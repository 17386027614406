<template>
  <div class="avatar-wrapper" :class="size != undefined ? size : ''">
    <img v-if="avatarURL != undefined" :src="avatarURL" alt="Profile picture" />
    <span v-else-if="fullName != undefined">{{
      getNameInitials(fullName)
    }}</span>
  </div>
</template>

<script>
export default {
  name: "ProfileAvatar",
  data() {
    return {
      backgroundColor: null,
    };
  },
  props: {
    fullName: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
    },
    avatarURL: {
      type: String,
      required: false,
    },
  },
  methods: {
    getNameInitials(personName) {
      if (personName != undefined && personName.indexOf(" ") >= 0) {
        personName = personName.split(" ");
      }
      var profileName = "";
      for (var i = 0; i < personName.length; i++) {
        if (i < 2) {
          profileName += personName[i].charAt(0);
        } else break;
      }

      return profileName;
    },
  },
};
</script>

<style scoped lang="scss">
.avatar-wrapper {
  position: relative;
  display: block;
  position: relative;
  background-color: $purpleColor;
  border: 1px solid lighten($purpleColor, 10%);
  text-align: center;
  height: 1.85rem;
  width: 1.85rem;
  overflow: hidden;
  border-radius: .5rem;

  span {
    position: absolute;
    color: lighten($purpleColor, 40%);
    font-size: $smallestFontSize;
    line-height: 0.9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
}

.large {
  height: 2.15rem;
  width: 2.15rem;

  span {
    font-size: $smallFontSize;
    line-height: 0.9rem;
  }
}

.extra-large {
  height: 3rem;
  width: 3rem;

  span {
    font-size: $largeFontSize;
  }
}

.extra-extra-large {
  height: 5rem;
  width: 5rem;

  span {
    font-size: $largestFontSize;
  }
}
</style>