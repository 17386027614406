<template>
  <div v-if="showComponent" class="authentication-component">
    <!--START: Vue Headful-->
    <vue-headful :title="meta.title" />
    <!--END: Vue Headful-->

    <!--START: Logo-->
    <img src="@/assets/images/logo.png" class="logo" alt="Macha Logo" />
    <!--END: Logo-->

    <div class="authentication-wrapper">
      <!--START: Illustration-->
      <img
        src="@/assets/images/doodles/workspace-selection.png"
        class="card-illustration"
        alt="Welcome to Macha"
      />
      <!--END: Illustration-->

      <div class="card">
        <!--START: Intro-->
        <div class="intro-wrapper">
          <h1>Choose your workspace</h1>
          <p>
            <b>{{ email }}</b> has access to the following workspaces
          </p>
        </div>
        <!--END: Intro-->

        <!--START: Workspace selection-->
        <div class="workspaces-wrapper">
          <div
            v-for="workspace in workspaces"
            :key="workspace.id"
            class="workspace-item"
          >
            <!--START: Logo-->
            <div class="workspace-logo">
              <img
                v-if="workspace.logoURL != undefined"
                :src="workspace.logoURL"
                :alt="workspace.name"
              />
              <ProfileAvatar
                v-else
                :fullName="workspace.name"
                size="extra-large"
              ></ProfileAvatar>
            </div>
            <!--END: Logo-->

            <!--START: Workspace Info-->
            <div class="workspace-info">
              <h3>{{ workspace.storeName }}</h3>
              <span>{{ workspace.storeURL }}</span>
            </div>
            <!--END: Workspace Info-->

            <!--START: Action-->
            <div class="workspace-action">
              <button class="btn btn-icon">
                <unicon name="angle-right-b"></unicon>
              </button>
            </div>
            <!--END: Action-->
          </div>
        </div>
        <!--END: Workspace selection-->

        <!--START: Status Message-->
        <StatusMessage
          :show="status.show"
          :status="status.status"
          :message="status.message"
        ></StatusMessage>
        <!--END: Status Message-->

        <!--START: Loader-->
        <LineLoader :show="showLoader"></LineLoader>
        <!--END: Loader-->
      </div>

      <!--START: Alternate Option-->
      <div class="alternate-option">
        <p>Can't find your workspace?</p>
        <router-link to="/sign-in">Try a different e-mail address</router-link>
      </div>
      <!--END: Alternate Option-->
    </div>
  </div>
</template>
    
<script>
//Importing components
import ProfileAvatar from "@/components/misc/ProfileAvatar";
import LineLoader from "@/components/loaders/LineLoader";
import StatusMessage from "@/components/modals/StatusMessage";

export default {
  name: "StoreSelection",
  data() {
    return {
      email: "ankeet.guha@gmail.com",
      showComponent: true,
      showLoader: false,
      meta: {
        title: "Select Your Workspace",
      },
      status: {
        show: false,
        status: null,
        message: null,
      },
      workspaces: [
        {
          logoURL:
            "https://macha.s3.us-east-2.amazonaws.com/uploads/images/bcc5c195-8793-4e74-bdb6-1ffe1d0e494c.jpg",
          storeName: "ONSRA Support",
          storeURL: "https://onsra.com",
        },
        {
          logoURL:
            "https://macha.s3.us-east-2.amazonaws.com/uploads/images/bcc5c195-8793-4e74-bdb6-1ffe1d0e494c.jpg",
          storeName: "My Company",
          storeURL: "https://onsra.eu",
        },
        {
          logoURL: null,
          storeName: "My Company",
          storeURL: null,
        },
      ],
    };
  },
  components: {
    ProfileAvatar,
    LineLoader,
    StatusMessage,
  },
  async created() {
    // if (!(await this.authenticateStaff("/dashboard"))) this.showComponent = true;
  },
  methods: {
    redirectStaff(staff) {
      this.$store.commit("updateUser", {
        staff: staff,
        loggedIn: true,
      });
      setTimeout(() => {
        this.$router.push({ path: "/dashboard" });
      }, 3000);
    },

    fieldChanged() {},
  },
};
</script>
    
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
@import "@/assets/styles/shared/authentication.scss";

.authentication-wrapper {
  position: relative;
  width: 40%;
  margin-top: 4rem;
  transform: none;
  left: auto;
  top: auto;

  .card {
    overflow: hidden;
  }
}

.logo {
  position: absolute;
  top: 2rem;
  left: 2rem;
}

.card-illustration {
  position: relative;
  left: auto;
  bottom: auto;
  height: auto;
  width: 45%;
  margin-bottom: -2rem;
}

.line-loader {
  position: absolute;
  bottom: 0;
  left: 0;
}

.workspace-item {
  @include transition;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: $lightWhiteColor;
  border: 1px solid darken($lightWhiteColor, 5%);
  margin-bottom: 1rem;

  .workspace-logo {
    margin-right: 1.5rem;

    img {
      display: block;
      width: 3rem;
      border-radius: 0.5rem;
      border: 1px solid darken($whiteColor, 10%);
    }
  }

  .workspace-info {
    flex: 1;
    text-align: left;

    h3 {
      font-size: $mediumFontSize;
      margin-bottom: 0;
    }

    span {
      color: $greyColor;
      font-size: $smallFontSize;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: lighten($purpleColor, 40%);
    border: 1px solid lighten($purpleColor, 15%);
  }
}
</style>