<template>
  <div>
    <label class="field-label" v-if="field.placeholder != undefined">{{
      field.placeholder
    }}</label>
    <div
      class="field-radio-wrapper field-radio-toggle-wrapper"
      :class="
        field.isToggle != undefined && field.isToggle
          ? 'field-radio-toggle-wrapper'
          : ''
      "
    >
      <div
        class="field-radio-item"
        v-for="option in field.options"
        :class="{ error: field.hasError }"
        :key="option.id"
      >
        <input
          :id="option.name"
          :name="option.placeholder"
          type="radio"
          :value="option.value"
          :checked="option.value == field.value"
          @focus="field.hasError = false"
          @click="changeEvent(option.value)"
        />
        <label :for="option.name">{{ option.name }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultipleToggleButton",
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  created() {},
  methods: {
    changeEvent(value) {
      this.field.value = value;
      this.$emit("fieldChanged", this.field.value);
    },
  },
};
</script>