<template>
  <div>
    <!--START: Line Loader-->
    <div class="line-loader" :class="[{ animate: show }, additionalClass]">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <!--END: Line Loader-->

    <!--START: Grey Screen-->
    <div
      v-if="showScreen && show"
      :class="additionalClass"
      class="grey-screen"
    ></div>
    <!--END: Grey Screen-->
  </div>
</template>

<script>
export default {
  name: "LineLoader",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    showScreen: {
      type: Boolean,
      required: false,
    },
    additionalClass: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
$loaderColor: $greenColor;

.grey-screen {
  position: fixed;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  z-index: 46;
  &.top {
    top: 5.75rem;
    height: calc(100% - 5.5rem);
  }
}
.line-loader {
  transition: all $transitionSpeed;
  position: relative;
  width: 100%;
  height: 0px;
  opacity: 0;
  overflow: hidden;
  z-index: 47;

  &.bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  &.top {
    top: 4.85rem;
    position: fixed;
    left: 0;
  }

  > div {
    display: block;
    position: absolute;
    height: 100%;
    left: 50%;
  }
  div:nth-child(1) {
    background-color: darken($loaderColor, 0%);
  }
  div:nth-child(2) {
    background-color: darken($loaderColor, 10%);
  }
  div:nth-child(3) {
    background-color: darken($loaderColor, 20%);
  }
  div:nth-child(4) {
    background-color: darken($loaderColor, 30%);
  }
}

.line-loader.animate {
  height: 10px;
  opacity: 1;
  div:nth-child(1) {
    animation: stretch 2.8s ease 0s infinite;
  }
  div:nth-child(2) {
    animation: stretch 2.8s ease 0.4s infinite;
  }
  div:nth-child(3) {
    animation: stretch 2.8s ease 0.5s infinite;
  }
  div:nth-child(4) {
    animation: stretch 2.8s ease 0.9s infinite;
  }

  &.large {
    height: 20px;
  }
}

@keyframes stretch {
  0% {
    padding: 0 0 0 0;
    left: 50%;
    z-index: 4;
  }
  25% {
    z-index: 3;
  }
  50% {
    padding: 0 50% 0 50%;
    left: 0;
    z-index: 2;
  }
  100% {
    padding: 0 50% 0 50%;
    left: 0;
    z-index: 1;
  }
}
</style>