<template>
  <div class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
      <button
        class="btn btn-small btn-primary"
        :disabled="showOptions.disableButton || showOptions.saveInProgress"
        @click="updateCompany"
      >
        Save
      </button>
    </div>
    <!--END: Header Wrapper-->

    <!--START: Loader-->
    <PageLoader v-if="lodash.isEmpty(aiModel)" :showLoader="true"></PageLoader>
    <!--END: Loader-->

    <!--START: Wrapper-->
    <div v-else class="settings-form card" @scroll="sourcesScroll">
      <FormBuilder :fields="fields" @fieldChanged="fieldChanged"></FormBuilder>
    </div>
    <!--END: Wrapper-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
              
<script>
// Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";
import PageLoader from "@/components/loaders/PageLoader";
import FormBuilder from "@/components/form/FormBuilder";

// Importing Services
import "external-svg-loader";
import { AIModelService } from "@/services";

export default {
  name: "CompanySettings",
  data() {
    return {
      lodash: _,
      showOptions: {
        headerShadow: false,
        lineLoader: false,
        disableButton: true,
      },
      regularHeader: {
        title: "Company Details",
        description:
          "Add details of your company; the AI will use this to generate replies",
      },
      fields: {
        companyName: {
          type: "text",
          title: "Company Name",
          required: false,
          hasError: false,
          placeholder: "Add the company name",
          value: "",
        },
        companyDescription: {
          type: "textarea",
          placeholder: "Describe the characteristics of the company",
          required: false,
          hasError: false,
          maxlength: 250,
          title: "Company Description",
          value: "",
        },
      },
      aiModel: {},
      initData: {},
      dataChanged: false,
      status: {
        show: false,
        status: "success",
        title: "Document deleted",
      },
    };
  },
  props: {},
  watch: {},
  components: {
    RegularHeader,
    NotificationMessage,
    LineLoader,
    PageLoader,
    FormBuilder,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  async created() {
    await this.getAiModel();
  },
  methods: {
    // Get AI model
    async getAiModel() {
      const response = await AIModelService.GetWidget();
      if (!response.error) {
        this.aiModel = response.data;
        this.populateFields();
      }
    },

    // Company added
    async updateCompany() {
      this.showOptions.lineLoader = true;
      const data = { ...this.fields };
      var isFormValid = this.validateForm(data);

      if (isFormValid) {
        const payload = this.constructPayload(this.parseData(data));
        const response = await AIModelService.UpdateCompany(payload);
        this.aiModel = response.data;
        this.populateFields();

        if (!response.hasError) {
          this.showStatusMessage(this.status, 2500, response.message);
        }
      }

      this.showOptions.lineLoader = false;
    },

    // Populate the fields
    populateFields() {
      if (!_.isEmpty(this.aiModel)) {
        this.fields.companyName.value = this.aiModel.companyName;
        this.fields.companyDescription.value = this.aiModel.companyDescription;
      }

      this.initFormData();
    },

    constructPayload() {
      const payload = this.parseData(this.fields);
      return payload;
    },

    // Initialise the form data for comparisons
    initFormData() {
      const data = {
        ...this.fields,
      };

      this.disableSaveButton(true);
      this.initData = _.cloneDeep(data);
    },

    // Show the modal header options
    disableSaveButton(status) {
      this.dataChanged = !status;
      this.showOptions.disableButton = status;
    },

    // Update rule when fields change
    fieldChanged() {
      const data = {
        ...this.fields,
      };
      this.disableSaveButton(this.isDataEqual(this.initData, data));
    },

    // Event on sourcesScroll scroll
    sourcesScroll() {
      const scrollTop = this.$refs.sources.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },
  },
};
</script>
              
  <style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1.5rem;

  .regular-header {
    flex: 1;
    max-width: 70%;
    margin-right: 30%;
  }
}

.zero-state {
  margin-top: 2.5rem;
}

.page-loader-wrapper {
  margin-top: 2rem;
}

/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}

.show-shadow {
  box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
}
</style>