<template>
  <div class="field-wrapper">
    <div class="field-money-item">
      <span class="money-label">{{ getCurrency() }}</span>
      <input
        type="number"
        :name="field.name"
        :placeholder="field.placeholder"
        :required="field.required"
        :disabled="field.disabled != undefined ? field.disabled : false"
        :class="{ error: field.hasError }"
        @focus="field.hasError = false"
        @input="fieldChanged"
        v-model="field.value"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "MoneyField",
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  methods: {
    fieldChanged() {
      this.$emit("fieldChanged");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.field-money-item {
  position: relative;
  .money-label {
    font-size: $normalFontSize;
    color: $brandColor;
    line-height: 2.5rem;
    background-color: $lightWhiteColor;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    text-align: center;
    position: absolute;
    top: 1px;
    left: 1px;
    height: calc(100% - 2px);
    width: 2rem;
  }
  input {
    padding: 0.75rem 1.15rem 0.75rem 2.5rem;
    width: calc(100% - 3.85rem);
  }
}
</style>