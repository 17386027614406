<template>
  <div class="plan-wrapper">
    <!--START: Info wrapper-->
    <div class="info-wrapper">
      <h3 class="section-title">Current Plan</h3>
      <p>Here are the details and usage of your current plan</p>
      <button
        v-if="subscription.subscriptionID"
        class="btn btn-small btn-border"
        @click="manageSubscription"
      >
        Manage subscription
      </button>
    </div>
    <!--END: Info wrapper-->

    <!--START: Active plan-->
    <div class="active-plan">
      <div v-if="subscription.status !== 'cancelled'" class="plan-details">
        <span class="details-title"
          >You are currently on the following plan</span
        >
        <em class="plan-name">{{ selectedPlan.planTitle }}</em>
        <span class="answer-limit">{{ answerLimit }} answers</span>
      </div>
      <div v-else class="plan-details cancelled-plan">
        <span class="details-title">You have cancelled your subscription</span>
        <em class="plan-name">No active plan</em>
        <span class="answer-limit">0 answers available</span>
      </div>

      <div v-if="subscription.status !== 'cancelled'" class="usage-wrapper">
        <span class="details-title">Your current monthly usage</span>
        <div class="usage-details">
          <div v-if="subscriptionTerm.startDate" class="term-details">
            <span>{{
              moment.unix(subscriptionTerm.startDate).format("DD MMMM YYYY")
            }}</span>
            to
            <span>{{
              moment.unix(subscriptionTerm.endDate).format("DD MMMM YYYY")
            }}</span>
          </div>
          <div class="answers-count">
            <span>{{ subscription.usage.generatedAnswers }}</span> of
            {{ answerLimit }} answers generated
          </div>
        </div>
        <div class="usage-bar">
          <span
            class="current-usage"
            :style="currentUsageStyle"
            :class="usageClass"
          ></span>
        </div>
      </div>
    </div>
    <!--END: Active plan-->
  </div>
</template>
    
<script>
export default {
  name: "ActivePlan",
  props: {
    subscription: Object,
    subscriptionTerm: Object,
  },
  data() {
    return {
      plans: [
        {
          planType: "free",
          planTitle: "Free",
          limit: 50,
        },
        {
          planID: "Macha-AI-Zendesk-App-49-pm-plan-USD-Monthly",
          planType: "starter",
          planTitle: "Starter",
          limit: 250,
        },
        {
          planID: "Macha-AI-Zendesk-App-99-pm-plan-USD-Monthly",
          planType: "professional",
          planTitle: "Professional",
          limit: 750,
        },
        {
          planID: "Macha-AI-Zendesk-App-249-pm-plan-USD-Monthly",
          planType: "plus",
          planTitle: "Plus",
          limit: 2000,
        },
        {
          planType: "custom",
          planTitle: "Custom",
        },
      ],
    };
  },
  computed: {
    // Find the selected plan
    selectedPlan() {
      let selectedPlan = {};
      if (
        this.subscription.status === "active" &&
        (this.subscription.planType === "free" ||
          this.subscription.planType === "custom")
      )
        selectedPlan = this.plans.find(
          (p) => p.planType == this.subscription.planType
        );
      else
        selectedPlan = this.plans.find(
          (p) => p.planID == this.subscription.planID
        );

      return selectedPlan;
    },

    // Answer limit
    answerLimit() {
      let planLimit = this.selectedPlan.limit;
      if (this.subscription.answerLimit !== -1)
        planLimit = this.subscription.answerLimit;

      return planLimit;
    },

    // Send the current usage style
    currentUsageStyle() {
      return `width: ${
        (this.subscription.usage.generatedAnswers / this.answerLimit) * 100
      }%`;
    },

    // Determine the class based on the usage
    usageClass() {
      const currentUsage =
        (this.subscription.usage.generatedAnswers / this.answerLimit) * 100;

      if (currentUsage < 50) return "low";
      else if (currentUsage > 60 && currentUsage < 85) return "medium";
      else if (currentUsage < 100) return "high";

      return "exceeded";
    },
  },
  created() {},
  methods: {
    // Manage subscription
    manageSubscription() {
      this.$emit("manageSubscription");
    },
  },
};
</script>
    
<style scoped lang="scss">
.plan-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 3rem;
}

.info-wrapper {
  margin-bottom: 2rem;
  width: 35%;
  margin-right: 5rem;

  h3 {
    font-family: $titleFont;
    font-size: $largerFontSize;
    font-weight: $boldFontWeight;
  }

  p {
    font-size: $smallFontSize;
    opacity: $lightOpacity;
  }

  .btn {
    margin-top: 1rem;
  }
}

.active-plan {
  flex: 1;

  .details-title {
    display: block;
    color: $greyColor;
    font-size: $smallFontSize;
    margin-bottom: 0.5rem;
  }

  .plan-details {
    .plan-name {
      font-size: 2.75rem;
      color: $purpleColor;
      font-weight: 600;
    }

    .answer-limit {
      margin-left: 1rem;
      font-size: $normalFontSize;
      font-weight: 600;
      color: $greyColor;
    }

    &.cancelled-plan {
      .plan-name {
        display: block;
        color: $redColor;
        margin: 1rem 0 0.5rem;
      }

      .answer-limit {
        margin-left: 0;
      }
    }
  }

  .usage-wrapper {
    margin-top: 3rem;

    .usage-details {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: $smallerFontSize;
      color: lighten($greyColor, 10%);
      margin-bottom: 0.5rem;

      .term-details {
        flex: 1;
      }

      .answers-count {
        text-align: right;
      }

      span {
        color: $greyColor;
        font-weight: $boldFontWeight;
        font-size: $normalFontSize;
      }

      .term-details {
        flex: 1;

        span {
          font-size: $smallerFontSize;
          font-weight: 600;
        }
      }
    }

    .usage-bar {
      position: relative;
      overflow: hidden;
      background: #f2f0f9;
      height: 1rem;
      width: 100%;
      border-radius: 0.5rem;

      .current-usage {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;

        &.low {
          background-color: $purpleColor;
        }

        &.medium {
          background-color: $emeraldColor;
        }

        &.high {
          background-color: $orangeColor;
        }

        &.exceeded {
          background-color: $redColor;
        }
      }
    }
  }
}
</style>