<template>
  <div class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->

      <button class="btn btn-small btn-primary" @click="showAddUserModal">
        Add User
      </button>
    </div>
    <!--END: Header Wrapper-->

    <!--START: Loader-->
    <PageLoader
      v-if="lodash.isEmpty(company) || showOptions.pageLoader"
      :showLoader="true"
    ></PageLoader>
    <!--END: Loader-->

    <!--START: Users Access-->
    <div
      v-if="!showOptions.pageLoader"
      class="settings-form card"
      @scroll="sourcesScroll"
    >
      <UserAccessTable
        v-if="users.length"
        :users="users"
        @editUser="editUser"
        @deleteUser="deleteUser"
      ></UserAccessTable>

      <!--START: Zero State -->
      <ZeroState
        v-else
        class="zero-state"
        :imageURL="zeroStateData.imageURL"
        :title="zeroStateData.title"
        :message="zeroStateData.message"
        :buttonTitle="zeroStateData.buttonTitle"
        @eventHandler="showAddUserModal"
      ></ZeroState>
      <!--END: Zero State -->
    </div>
    <!--END: Users Access-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Add User-->
    <AddUserModal
      :show="showOptions.addUserModal"
      :user="userToEdit"
      @userAdded="userAdded"
      @closeModal="closeModal"
    ></AddUserModal>

    <div
      class="bg-overlay light"
      :class="{ show: showOptions.addUserModal }"
      @click="closeModal"
    ></div>
    <!--END: Add User-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
            
<script>
// Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";
import PageLoader from "@/components/loaders/PageLoader";
import ZeroState from "@/components/misc/ZeroState";

import UserAccessTable from "@/components/dashboard/tables/UserAccessTable";
import AddUserModal from "@/components/dashboard/userAccess/AddUserModal";

// Importing Services
import "external-svg-loader";
import { UserService } from "@/services";

export default {
  name: "UserAccess",
  data() {
    return {
      lodash: _,
      showOptions: {
        headerShadow: false,
        lineLoader: false,
        pageLoader: true,
        addUserModal: false,
      },
      regularHeader: {
        title: "User Access",
        description: "Manage user access to the Macha dashboard",
      },
      userToEdit: {},
      users: [],
      status: {
        show: false,
        status: "success",
        title: "Document deleted",
      },
      zeroStateData: {
        imageURL: "services.jpg",
        title: "No users in your team",
        message: "Add users to your team to give them dashboard access",
        buttonTitle: "Add User",
      },
    };
  },
  props: {},
  watch: {},
  components: {
    RegularHeader,
    NotificationMessage,
    LineLoader,
    PageLoader,
    ZeroState,
    UserAccessTable,
    AddUserModal,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  async created() {
    this.getUsers();
  },
  methods: {
    // Get users
    async getUsers() {
      const response = await UserService.GetUsers();
      if (!response.hasError) {
        this.users = response.data;
        this.users = _.orderBy(this.users, ["name"], ["desc"]);
      }

      this.showOptions.pageLoader = false;
    },

    // User added
    async userAdded() {
      this.users.splice(0);
      this.showOptions.pageLoader = true;

      this.closeModal();
      await this.getUsers();

      this.showOptions.pageLoader = false;
    },

    // Add user
    showAddUserModal() {
      this.userToEdit = {};
      this.showOptions.addUserModal = true;
    },

    // Edit a user
    editUser(user) {
      this.showAddUserModal();
      this.userToEdit = user;
    },

    // Delete user from company
    async deleteUser(userID) {
      const data = { userID };

      await UserService.DeleteUser(data);

      // Remove from the table
      const index = this.users.findIndex((u) => u._id == userID);
      this.users.splice(index, 1);

      this.status.title = "User deleted";
      this.showStatusMessage(this.status, 2500);
    },

    // Close the add modal
    closeModal() {
      this.showOptions.addUserModal = false;
    },

    // Event on sourcesScroll scroll
    sourcesScroll() {
      const scrollTop = this.$refs.sources.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },
  },
};
</script>
            
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1.5rem;

  .regular-header {
    flex: 1;
    max-width: 70%;
    margin-right: 30%;
  }
}

.zero-state {
  margin-top: 2.5rem;
}

.page-loader-wrapper {
  margin-top: 2rem;
}

/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}

.show-shadow {
  box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
}
</style>