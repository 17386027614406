<template>
  <!--START: Settings Form-->
  <div
    class="settings-form card"
    :class="[
      additionalClass,
      { 'accordion-wrapper': options.isAccordion },
      { 'accordion-wrapper-show': showAccordionBlock },
    ]"
  >
    <!--START: Accordion Title-->
    <div v-if="options.isAccordion" class="accordion-block">
      <div class="accordion-title-wrapper">
        <h3>{{ options.accordionContent.title }}</h3>
        <p>{{ options.accordionContent.description }}</p>
      </div>
      <div class="accordion-btn-wrapper">
        <button
          class="btn btn-small btn-narrow"
          :class="showAccordionBlock ? 'btn-border' : 'btn-primary'"
          @click="toggleAccordion"
        >
          <unicon :name="showAccordionBlock ? 'minus' : 'plus'"></unicon>
          <span>{{ showAccordionBlock ? "Hide" : "Show" }}</span>
        </button>
      </div>
    </div>
    <!--END: Accordion Title-->

    <!--START: Form Content-->
    <div
      v-if="options.isAccordion != true || showAccordionBlock"
      class="settings-form-wrapper"
    >
      <div v-if="content" class="content-wrapper">
        <h3 v-if="content.title != undefined">{{ content.title }}</h3>
        <p v-if="content.description != undefined">{{ content.description }}</p>
      </div>
      <FormBuilder
        class="form-builder"
        :fields="fields"
        @fieldChanged="fieldChanged()"
      ></FormBuilder>
    </div>
    <!--END: Form Content-->
  </div>
  <!--END: Settings Form-->
</template>

<script>
//Importing components
import FormBuilder from "@/components/form/FormBuilder";

export default {
  name: "SettingsForm",
  props: {
    additionalClass: String,
    content: Object,
    fields: Object,
    options: { type: Object, required: false, default: () => ({}) },
  },
  data() {
    return {
      showAccordionBlock: false,
    };
  },
  components: {
    FormBuilder,
  },
  methods: {
    toggleAccordion() {
      this.showAccordionBlock = !this.showAccordionBlock;
    },
    fieldChanged() {
      this.$emit("fieldChanged");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
</style>