require("dotenv").config();
import axios from "axios";

const apiURL = `${process.env.VUE_APP_API_URL}/user`;

class UserService {
  // Service to get user
  static GetUser() {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .get(`${apiURL}/`, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.error("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Service to get all users
  static GetUsers() {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .get(`${apiURL}/all`, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.error("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Service to add user
  static AddUser(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/add-users`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.error("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Service to update user
  static UpdateUser(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/update`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.error("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Service to delete user
  static DeleteUser(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/delete`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.error("err", e);
            reject(e);
          }
        })()
      );
    });
  }
}

export default UserService;
