<template>
  <div class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
    </div>
    <!--END: Header Wrapper-->

    <!--START: Loader-->
    <PageLoader
      v-if="lodash.isEmpty(company) || showOptions.pageLoader"
      :showLoader="true"
    ></PageLoader>
    <!--END: Loader-->

    <div v-else ref="scrollWrapper" class="scroll-main" @scroll="contentScroll">
      <!--START: Active Plan-->
      <ActivePlan
        :subscription="currentSubscription"
        :subscriptionTerm="subscriptionTerm"
        @manageSubscription="manageSubscription"
      ></ActivePlan>
      <!--END: Active Plan-->

      <!--START: Available Plans-->
      <AvailablePlans
        :subscription="currentSubscription"
        @subscribePlan="subscribePlan"
      ></AvailablePlans>
      <!--END: Available Plans-->
    </div>

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
              
<script>
// Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import ActivePlan from "@/components/dashboard/subscriptions/ActivePlan";
import AvailablePlans from "@/components/dashboard/subscriptions/AvailablePlans";
import LineLoader from "@/components/loaders/LineLoader";
import PageLoader from "@/components/loaders/PageLoader";

// Importing Services
import { SubscriptionService } from "@/services";

import "external-svg-loader";

export default {
  name: "Subscription",
  data() {
    return {
      lodash: _,
      showOptions: {
        headerShadow: false,
        lineLoader: false,
        pageLoader: false,
      },
      subscriptionTerm: {
        startDate: null,
        endDate: null,
      },
      regularHeader: {
        title: "Subscriptions",
        description: "Manage your subscriptions and track usage",
      },
    };
  },
  props: {},
  watch: {},
  components: {
    RegularHeader,
    ActivePlan,
    AvailablePlans,
    LineLoader,
    PageLoader,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },

    // The current subscription
    currentSubscription() {
      return this.company.subscription;
    },
  },
  async created() {
    this.getActiveSubscription();
  },
  mounted() {
    this.addChargebeeScript();
  },
  methods: {
    // Get the active subscription
    async getActiveSubscription() {
      const response = await SubscriptionService.GetActiveSubscription();
      if (!response.hasError) {
        const { startDate, endDate } = response.data;
        this.subscriptionTerm = { startDate, endDate };
      }
    },

    // Reload the subscription view after the Chargebee portal is close
    async reloadSubscription() {
      this.showOptions.pageLoader = true;
      await this.getUser();
      this.showOptions.pageLoader = false;
    },

    // Add Chargebee script to header
    addChargebeeScript() {
      let chargebeeScript = document.createElement("script");
      chargebeeScript.setAttribute(
        "src",
        "https://js.chargebee.com/v2/chargebee.js"
      );
      document.head.appendChild(chargebeeScript);
    },

    // Subscribe to the plan
    async subscribePlan(planID) {
      this.showOptions.lineLoader = true;
      const response = await SubscriptionService.GetSubscriptionHostedPage({
        planID,
      });

      this.showOptions.lineLoader = false;

      this.chargebeeInstance = window.Chargebee.init({
        site: process.env.VUE_APP_CHARGEBEE_SITE,
        publishableKey: process.env.VUE_APP_CHARGEBEE_PUBLISHABLE_KEY,
      });

      const ele = this;
      let chargebeeInstance = window.Chargebee.getInstance();
      chargebeeInstance.openCheckout({
        hostedPage: function () {
          return new Promise(function (resolve) {
            resolve(response.data.hosted_page);
          });
        },

        success: async function () {
          ele.reloadSubscription();
        },
      });
    },

    // Manage subscription
    async manageSubscription() {
      this.showOptions.lineLoader = true;
      const response = await SubscriptionService.GetPortalSession();
      this.showOptions.lineLoader = false;

      this.chargebeeInstance = window.Chargebee.init({
        site: process.env.VUE_APP_CHARGEBEE_SITE,
        publishableKey: process.env.VUE_APP_CHARGEBEE_PUBLISHABLE_KEY,
      });

      const chargebeeInstance = window.Chargebee.getInstance();
      chargebeeInstance.setPortalSession(function () {
        return new Promise(function (resolve) {
          resolve(response.data);
        });
      });

      let cbPortal = chargebeeInstance.createChargebeePortal();
      const ele = this;
      cbPortal.open({
        close() {
          ele.reloadSubscription();
        },
      });
    },

    // Event on sourcesScroll scroll
    contentScroll() {
      const scrollTop = this.$refs.scrollWrapper.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },
  },
};
</script>
              
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.header-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e9e6ef;
  margin-bottom: 0 !important;
  z-index: 2;

  .regular-header {
    flex: 1;
    max-width: 70%;
    margin-right: 30%;
  }
}

.scroll-main {
  flex: 1;
  padding: 2rem 1.5rem;
  overflow-y: scroll;
}

.page-loader-wrapper {
  margin-top: 2rem;
}

/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}

.show-shadow {
  box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
}
</style>