<template>
    <div class="page-main flex-page">
      <!--START: Header Wrapper-->
      <div class="header-wrapper">
        <!--START: Page Header-->
        <RegularHeader
          class="regular-header"
          :data="regularHeader"
        ></RegularHeader>
        <!--END: Page Header-->
  
        <button class="btn btn-small btn-grey" @click="addAnswerRule">
          Add Answer Rule
        </button>
      </div>
      <!--END: Header Wrapper-->
  
      <!--START: Zero State -->
      <ZeroState
        v-if="showOptions.zeroState"
        class="zero-state"
        :imageURL="zeroStateData.imageURL"
        :title="zeroStateData.title"
        :message="zeroStateData.message"
        :buttonTitle="zeroStateData.buttonTitle"
        @eventHandler="addAnswerRule"
      ></ZeroState>
      <!--END: Zero State -->
  
      <!--START: Answer Rules Table-->
      <AnswerRulesTable
        v-else-if="rules"
        :rules="rules"
        @ruleSelected="showRuleModal"
        @deleteRule="deleteRule"
      ></AnswerRulesTable>
      <!--END: Answer Rules Table-->
  
      <!--START: Loader-->
      <PageLoader v-if="!showOptions.initRules" :showLoader="true"></PageLoader>
      <!--END: Loader-->
  
      <!--START: View Document-->
      <ViewAnswerRule
        v-if="showOptions.viewRule"
        :show="showOptions.viewRule"
        :ruleID="selectedRuleID"
        @ruleAdded="ruleAdded"
        @closeModal="closeModal"
      ></ViewAnswerRule>
      <div
        class="bg-overlay light"
        :class="{ show: showOptions.viewRule }"
        @click="closeModal"
      ></div>
      <!--END: View Document-->
  
      <!--START: Notification Message-->
      <NotificationMessage
        :show="status.show"
        :status="status.status"
        :title="status.title"
        @closeModal="closeModal"
      ></NotificationMessage>
      <!--END: Notification Message-->
  
      <!--START: Loader-->
      <LineLoader :show="showOptions.lineLoader"></LineLoader>
      <!--END: Loader-->
    </div>
  </template>
          
  <script>
  //Import libraries
  import _ from "lodash";
  
  // Importing components
  import RegularHeader from "@/components/dashboard/RegularHeader";
  import ZeroState from "@/components/misc/ZeroState";
  import NotificationMessage from "@/components/modals/NotificationMessage";
  import LineLoader from "@/components/loaders/LineLoader";
  import PageLoader from "@/components/loaders/PageLoader";
  
  import AnswerRulesTable from "@/components/dashboard/tables/AnswerRulesTable";
  import ViewAnswerRule from "@/components/dashboard/knowledgeBase/ViewAnswerRule";
  
  // Importing Services
  import { AnswerRuleService } from "@/services";
  import "external-svg-loader";
  
  export default {
    name: "AnswerRules",
    data() {
      return {
        showOptions: {
          lineLoader: false,
          initRules: false,
          zeroState: false,
          viewRule: false,
        },
        regularHeader: {
          title: "Answer Rules",
          description:
            "Create answer rules for the AI to follow for it's responses",
        },
        zeroStateData: {
          imageURL: "services.jpg",
          title: "No Answer Rules",
          message:
            "Add answer rules to determine how you want the AI to create responses",
          buttonTitle: "Add Answer Rule",
        },
        status: {
          show: false,
          status: "success",
          title: "",
        },
        selectedAnswerRule: "",
        rules: [],
        gettingRules: false,
      };
    },
    props: {},
    watch: {},
    components: {
      RegularHeader,
      ZeroState,
      NotificationMessage,
      LineLoader,
      PageLoader,
      AnswerRulesTable,
      ViewAnswerRule,
    },
    computed: {
      company() {
        return this.$store.getters.company;
      },
    },
    async created() {
      await this.getAnswerRules();
    },
  
    methods: {
      // Delete answe rule
      async deleteRule(ruleID) {
        const data = { answerRuleID: ruleID };
  
        await AnswerRuleService.DeleteRule(data);
  
        // Remove from the table
        const index = this.rules.findIndex((r) => r._id == ruleID);
        this.rules.splice(index, 1);
  
        this.status.title = "Answer rule deleted";
        this.showStatusMessage(this.status, 2500);
  
        if (this.rules.length === 0) this.showOptions.zeroState = true;
      },
  
      // When a rule has been added or updated
      ruleAdded(rule) {
        this.selectedRuleID = rule._id;
  
        let tempRules = _.cloneDeep(this.rules);
        const index = this.rules.findIndex((r) => r._id == rule._id);
  
        if (index == -1) tempRules.push(rule);
        else tempRules[index] = rule;
  
        this.rules.splice(0);
        this.rules = _.cloneDeep(tempRules);
        this.showOptions.zeroState = false;
      },
  
      // Get all the rules
      async getAnswerRules() {
        this.gettingRules = true;
        const response = await AnswerRuleService.GetAllRules();
        this.rules.push(...response.data);
  
        if (response.data == 0) this.showOptions.zeroState = true;
  
        this.gettingRules = false;
        this.showOptions.initRules = true;
      },
  
      // Show the selected document details
      showRuleModal(ruleID) {
        this.selectedRuleID = ruleID;
        this.showOptions.viewRule = true;
      },
  
      addAnswerRule() {
        this.selectedRuleID = "";
        this.showOptions.viewRule = true;
      },
  
      // Close all the modals
      closeModal() {
        this.status.show = false;
        this.showOptions.viewRule = false;
      },
    },
  };
  </script>
          
  <style scoped lang="scss">
  .header-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 1.5rem;
  
    .regular-header {
      flex: 1;
      max-width: 70%;
      margin-right: 30%;
    }
  }
  
  .zero-state {
    margin-top: 2.5rem;
  }
  
  .page-loader-wrapper {
    margin-top: 2rem;
  }
  
  /deep/ .line-loader {
    position: fixed;
    z-index: 101;
    bottom: 0;
    left: 0;
  }
  </style>