<template>
  <div class="success-form" :class="{ show: successForm.show }">
    <unicon name="check"></unicon>
    <div class="success-info-wrapper">
      <h3>{{ successForm.title }}</h3>
      <p>{{ successForm.message }}</p>
      <div class="success-btn-wrapper">
        <button
          type="button"
          v-if="successForm.primaryBtn != undefined"
          class="btn btn-primary btn-small"
          @click="primaryEventHandler"
        >
          {{ successForm.primaryBtn.title }}
        </button>
        <button
          type="button"
          v-if="successForm.secondaryBtn != undefined"
          class="btn btn-border btn-small"
          @click="secondaryEventHandler"
        >
          {{ successForm.secondaryBtn.title }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessForm",
  props: {
    successForm: {
      type: Object,
      required: true,
    },
  },
  methods: {
    primaryEventHandler() {
      this.$emit("primaryEvent");
    },
    secondaryEventHandler() {
      this.$emit("secondaryEvent");
    },
  },
};
</script>

<style scoped lang="scss">
.success-form {
  display: none;
  flex-direction: row;
  text-align: center;
}

.success-form.show {
  display: block;
}

.success-form .unicon /deep/ svg {
  display: block;
  align-items: center;
  fill: darken($greenColor, 30%);
  stroke: darken($greenColor, 30%);
  background-color: lighten($greenColor, 15%);
  border: 10px solid lighten($greenColor, 25%);
  padding: 0.75rem;
  width: 2rem;
  height: auto;
  border-radius: 50%;
  margin-bottom: 1rem;
  path {
    stroke-width: 2;
  }
}

.success-info-wrapper {
  h3 {
    font-size: $largeFontSize;
  }
  p {
    font-size: $smallFontSize;
    color: $greyColor;
  }
}

.success-btn-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 0;
  .btn {
    margin-right: 0.75rem;
  }
}
</style>