<template>
  <Transition name="modal">
    <div
      v-if="fields.show"
      class="confirmation-modal"
      :class="[
        fields.modalType,
        fields.modalType == 'fixed' ? 'card modal' : null,
      ]"
    >
      <div class="success-form">
        <div
          class="form-icon-wrapper"
          :class="{
            'delete-icon': fields.type != undefined && fields.type == 'delete',
          }"
        >
          <unicon class="check" name="check"></unicon>
          <unicon class="delete" name="times"></unicon>
        </div>
        <div class="success-info-wrapper">
          <h3>{{ fields.title }}</h3>
          <p>{{ fields.message }}</p>
          <div class="success-btn-wrapper">
            <button
              type="button"
              v-if="fields.primaryBtn != undefined"
              class="btn btn-primary btn-small"
              @click="primaryEventHandler"
            >
              {{ fields.primaryBtn.title }}
            </button>
            <button
              type="button"
              v-if="fields.secondaryBtn != undefined"
              class="btn btn-border btn-small"
              @click="secondaryEventHandler"
            >
              {{ fields.secondaryBtn.title }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: "ConfirmationModal",
  props: {
    fields: {
      type: Object,
      required: true,
    },
  },
  methods: {
    primaryEventHandler() {
      this.$emit("primaryEvent");
    },
    secondaryEventHandler() {
      this.$emit("secondaryEvent");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.confirmation-modal.fixed {
  width: auto;
  margin: auto;
  text-align: center;
  max-width: 20rem;
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(lighten($darkBlackColor, 25%), 0.35);
}
.success-form .unicon {
  align-items: center;
}

.success-form .unicon {
  /deep/ svg {
    display: block;
    padding: 0.75rem;
    border-radius: 50%;
    margin: auto;
  }
  .check {
    display: block;
  }

  .delete {
    display: none;
  }

  &.check /deep/ svg {
    fill: darken($greenColor, 40%);
    background-color: lighten($greenColor, 15%);
    border: 10px solid lighten($greenColor, 25%);
  }

  &.delete /deep/ svg {
    fill: darken($redColor, 30%);
    background-color: lighten($redColor, 15%);
    border: 10px solid lighten($redColor, 25%);
  }
}

.delete-icon {
  .check {
    display: none;
  }

  .delete {
    display: block;
  }
}

.success-info-wrapper {
  margin-top: 1rem;
  h3 {
    font-size: $largeFontSize;
    color: $titleColor;
  }
  p {
    font-size: $smallFontSize;
    color: $paragraphColor;
    margin: 1rem 0 1.5rem;
  }
}

.success-btn-wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  white-space: nowrap;

  .btn {
    margin-right: 0.75rem;
  }
}

@media only screen and (max-width: $mobileWidth) {
  .modal {
    width: calc(100% - 5rem);
    bottom: 0.5rem;
    top: auto;
    left: 0.5rem;
    height: auto;
    transform: translateY(100vh);

    &.show {
      transform: translateY(0);
    }
  }
}
</style>