import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      user: {},
      loggedIn: false,
    },
    company: {},
    widget: {},
    widgetLanguage: "en",
  },
  mutations: {
    updateUser(state, data) {
      state.user.user = data.user;
      state.user.loggedIn = data.loggedIn;
    },
    updateCompany(state, data) {
      state.company = data;
    },
    updateWidget(state, data) {
      state.widget = data;
    },
    updateWidgetLanguage(state, data) {
      state.widgetLanguage = data;
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    company(state) {
      return state.company;
    },
    categories(state) {
      return state.categories;
    },
    widget(state) {
      return state.widget;
    },
    widgetLanguage(state) {
      return state.widgetLanguage;
    },
  },
});
