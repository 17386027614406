<template>
  <!--START: Pre loader-->
  <div v-if="showOptions.preLoader" class="pre-loader-wrapper">
    <PreLoader :show="true" v-for="index in 2" :key="index"></PreLoader>
  </div>
  <!--START: Pre loader-->

  <!--START: Article-->
  <div v-else class="page-main flex-page">
    <!--START: Header-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <div class="title-wrapper">
        <FormBuilder
          v-if="showOptions.titleInput"
          :fields="titleFields"
          class="title-form"
          @fieldChanged="adjustTitleWidth"
          v-click-outside="toggleTitleInput"
        ></FormBuilder>
        <div v-else class="title-info" @click="toggleTitleInput">
          <h3>{{ titleFields.title.value }}</h3>
          <button class="btn btn-small btn-text">
            <unicon name="pen"></unicon>
            <span>Edit</span>
          </button>
        </div>
      </div>

      <div class="actions-wrapper">
        <FormBuilder
          :fields="publishFields"
          class="publish-form"
          @fieldChanged="fieldChanged"
        ></FormBuilder>
        <button
          class="btn btn-small btn-primary"
          :disabled="showOptions.disableButton || showOptions.saveInProgress"
          @click="saveArticle"
        >
          Save
        </button>
      </div>
    </div>
    <!--END: Header-->

    <!--START: Article-->
    <div class="article-body" ref="article" @scroll="articleScroll">
      <div v-for="(chunk, i) in chunks" :key="chunk._id" class="chunk-block">
        <!--START: Chunk Actions-->
        <div class="chunk-actions">
          <span class="chunk-index">{{ `${i + 1} of ${chunks.length}` }}</span>
          <div class="tokens-wrapper" :class="getChunkCharacters(i).status">
            {{ getChunkCharacters(i).charsLeft }} chars left
          </div>
          <button
            v-if="chunks.length > 1"
            class="btn btn-icon btn-delete btn-small"
            @click="deleteChunk(chunk, i)"
          >
            <unicon name="trash-alt"></unicon><span>Delete Block</span>
          </button>
        </div>
        <!--END: Chunk Actions-->

        <FormBuilder
          :fields="{ field: chunk }"
          class="article-form"
          @fieldChanged="fieldChanged"
        ></FormBuilder>
      </div>
    </div>
    <!--END: Article-->

    <!--START: Article Actions-->
    <div class="article-actions-wrapper">
      <button class="btn btn-black" @click="addChunk">
        <unicon name="plus"></unicon>
        <span>Add Block</span>
      </button>
    </div>
    <!--END: Article Actions-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->

    <!--START: Unsaved Changes Modal -->
    <UnsavedChangesModal
      :show="showOptions.unsavedChanges"
      @primaryEvent="continueRoute"
      @secondaryEvent="closeModal"
    ></UnsavedChangesModal>
    <!--END: Unsaved Changes Modal -->
  </div>
  <!--END: Article-->
</template>
        
<script>
//Import libraries
import _ from "lodash";
import vClickOutside from "v-click-outside";

// Importing components
import FormBuilder from "@/components/form/FormBuilder";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";
import PreLoader from "@/components/loaders/PreLoader";
import UnsavedChangesModal from "@/components/modals/UnsavedChangesModal";

// Importing Services
import { DocumentService } from "@/services";
import "external-svg-loader";

export default {
  name: "AddArticle",
  data() {
    return {
      showOptions: {
        preLoader: true,
        lineLoader: false,
        headerShadow: false,
        titleInput: true,
        disableButton: true,
        unsavedChanges: false,
      },
      referenceID: null,
      titleFields: {
        title: {
          type: "text",
          placeholder: "Untitled Article",
          required: true,
          hasError: false,
          value: "",
        },
      },
      publishFields: {
        isActive: {
          type: "toggle",
          title: "Publish article",
          additionalClass: "large-toggle",
          value: true,
        },
      },
      chunks: [],
      deleteChunkIDs: [],
      editedChunkIDs: [],
      initData: {},
      initChunks: [],
      articleField: {
        id: null,
        type: "text-editor",
        placeholder: "Start writing...",
        value: null,
        hasError: false,
      },
      dataChanged: false,
      status: {
        show: false,
        status: "success",
        title: "Article saved",
      },
      saveInProgress: false,
    };
  },
  props: {},
  watch: {},
  components: {
    FormBuilder,
    NotificationMessage,
    LineLoader,
    PreLoader,
    UnsavedChangesModal,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  async created() {
    await this.getDocument();
  },

  methods: {
    // Save article
    async saveArticle() {
      if (!this.saveInProgress) {
        this.saveInProgress = true;
        this.showOptions.lineLoader = true;

        const payload = this.constructPayload();
        const response = await DocumentService.SaveDocument(payload);

        // Check for errors
        if (!response.hasError) {
          const documents = response.data;

          if (!this.referenceID) {
            const path = `/knowledge-base/articles/add/${documents[0].reference_id}`;
            this.$router.push({ path });
          }

          // Reset the document
          this.resetDocument();
          this.constructDocument(documents);
          this.initFormData();

          // Show status message
          this.showStatusMessage(this.status);
          this.showOptions.lineLoader = false;
        } else this.showErrorMessage(this.status, response.message);

        this.saveInProgress = false;
      }
    },

    // Get the document
    async getDocument() {
      this.resetDocument();

      this.referenceID = this.$route.params.referenceID;
      if (this.referenceID) {
        const data = {
          referenceID: this.referenceID,
          sourceID: this.company._id,
        };
        const response = await DocumentService.GetDocument(data);

        // Check for errors
        if (!response.hasError) this.constructDocument(response.data);
        else this.showErrorMessage(this.status, response.message);
      } else {
        this.addChunk(false);
      }

      // Hide the pre loader
      this.initFormData();
      this.showOptions.preLoader = false;
    },

    // Construct the document object
    constructDocument(chunks) {
      const { title, is_active, reference_id } = chunks[0];
      this.referenceID = reference_id;

      chunks.forEach((c, i) => {
        // Populate chunks
        this.addChunk(false);
        this.chunks[i].id = c.id;
        this.chunks[i].value = c.content;
      });

      // Init the fields
      this.titleFields.title.value = title;
      this.publishFields.isActive.value = is_active;
      this.showOptions.titleInput = false;
    },

    // Construct the payload to send
    constructPayload() {
      // Extract details
      const { value: title } = this.titleFields.title;
      const { value: is_active } = this.publishFields.isActive;

      let chunks = [];
      this.chunks.forEach((c) => {
        let obj = {};

        // Check if this is an existing chunk
        if (c.id) {
          // Add the id and check if edited value needs to be included
          obj.id = c.id;
          if (this.editedChunkIDs.includes(c.id)) obj.content = c.value;
        } else obj.content = c.value;

        chunks.push(obj);
      });

      const data = {
        deleteChunkIDs: this.deleteChunkIDs,
        document: {
          reference_id: this.referenceID ? this.referenceID : false,
          title,
          is_active,
          chunks,
        },
      };

      return data;
    },

    // Extract only the text
    extractContent(s) {
      var span = document.createElement("span");
      span.innerHTML = s;
      return span.textContent || span.innerText;
    },

    // Delete document from Supabase
    async deleteDocument(referenceID) {
      const data = {
        referenceID,
      };

      await DocumentService.DeleteDocument(data);

      this.status.title = "Document deleted";
      this.showStatusMessage(this.status, 2500);
    },

    // Show the modal header options
    disableSaveButton(status) {
      this.dataChanged = !status;
      this.showOptions.disableButton = status;
    },

    // Get character count
    getChunkCharacters(i) {
      const content = this.extractContent(this.chunks[i].value);
      const tokenLimit = 400;
      const characterMultiplier = 4;

      const charsLeft = tokenLimit * characterMultiplier - content.length;
      let status = "green";
      if (charsLeft < 200 && charsLeft >= 0) {
        status = "yellow";
      } else if (charsLeft < 0) {
        status = "red";
      }

      return { status, charsLeft };
    },

    // Add a new chunk
    addChunk(scrollToEnd = true) {
      const chunk = _.cloneDeep(this.articleField);
      this.chunks.push(chunk);

      if (scrollToEnd) this.scrollToEnd();
    },

    // Delete chunk
    deleteChunk(chunk, i) {
      if (chunk.id) this.deleteChunkIDs.push(chunk.id);
      this.chunks.splice(i, 1);
      this.fieldChanged(true);
    },

    // Event on chat scroll
    articleScroll() {
      if (this.$refs.article.scrollTop > 0)
        this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    // Initialise the form data for comparisons
    initFormData() {
      // Init the data
      const data = { ...this.titleFields, ...this.publishFields };
      this.initData = _.cloneDeep(data);

      // Init the chunks
      this.initChunks = _.cloneDeep(this.chunks);

      this.disableSaveButton(true);
    },

    // Event when form field changes
    fieldChanged(enableButton = false) {
      const data = { ...this.titleFields, ...this.publishFields };
      const fieldsEqual = this.isDataEqual(this.initData, data);

      // Iterate and compare if there are any changes in the chunks
      let chunksEqual = true;
      this.chunks.forEach((c) => {
        if (c.id) {
          const selectedChunk = this.initChunks.find(
            (chunk) => chunk.id === c.id
          );

          // Check if the chunks are the same
          if (!this.isDataEqual(c.value, selectedChunk.value)) {
            chunksEqual = false;
            if (!this.editedChunkIDs.includes(c.id))
              this.editedChunkIDs.push(c.id);
          }
        } else chunksEqual = false;
      });

      // Disable the button depending on if there are changes
      if (enableButton) this.disableSaveButton(false);
      else this.disableSaveButton(fieldsEqual && chunksEqual);
    },

    // Adjust the title width while typing
    adjustTitleWidth() {
      const title = this.titleFields.title.value;
      document.querySelector(".title-form input").style.width = `calc(${
        title.length - 5
      }ch)`;

      if (this.titleFields.title.value.length != 0)
        document.querySelector(".title-form input").focus();

      this.fieldChanged();
    },

    // Switch between title and input
    toggleTitleInput() {
      if (this.titleFields.title.value.length == 0)
        this.showOptions.titleInput = true;
      else this.showOptions.titleInput = !this.showOptions.titleInput;

      if (this.showOptions.titleInput)
        setTimeout(
          function () {
            this.adjustTitleWidth();
          }.bind(this),
          0
        );
    },

    scrollToEnd() {
      setTimeout(
        function () {
          this.$refs.article.scrollTop = this.$refs.article.scrollHeight;
        }.bind(this),
        0
      );
    },

    // Close all the modals
    closeModal() {
      this.status.show = false;
      this.showOptions.unsavedChanges = false;
    },

    // Reset all the fields for the document
    resetDocument() {
      this.editedChunkIDs.splice(0);
      this.deleteChunkIDs.splice(0);
      this.chunks.splice(0);
      this.initChunks.splice(0);

      this.initData = {};
    },

    // Discard all the changes
    discardChanges() {
      this.fields = _.cloneDeep(this.initData);
      this.disableSaveButton(true);
      this.closeModal();
    },

    // Discard the changes and move to the selected route
    continueRoute() {
      this.closeModal();
      this.discardChanges();
      if (this.toRoute != null) this.$router.push({ path: this.toRoute.path });
    },
  },
  // Check if there are any changes to be saved
  beforeRouteLeave(to, from, next) {
    if (this.dataChanged) {
      this.toRoute = to;
      this.showOptions.unsavedChanges = true;
    } else {
      next();
    }
  },
};
</script>
        
<style scoped lang="scss">
.header-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid $greyBorderColor;
  z-index: 2;

  .title-wrapper {
    position: relative;
    flex: 1;

    .title-info {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      transform: translateY(0.5rem);
      width: fit-content;
      margin-left: -0.75rem;
      padding: 0.5rem 0.75rem;
      border: 1px solid transparent;
      cursor: pointer;

      h3 {
        color: $inputTextColor;
        font-size: $mediumFontSize;
        font-weight: $mediumFontWeight;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 30vw;
      }

      .btn {
        position: absolute;
        top: 50%;
        left: calc(100% - 1rem);
        transform: translateY(-50%);
      }
    }

    .title-form {
      width: 15rem;
      transform: translateY(0.5rem);

      /deep/ {
        .field-main-item input {
          font-size: $mediumFontSize;
          margin-left: -0.75rem;
          padding: 0.5rem 0.75rem;
          max-width: 40vw;
          min-width: 15rem;
          transition: none;
          border-color: transparent !important;

          &::placeholder {
            font-weight: $mediumFontWeight;
            font-size: $mediumFontSize;
          }

          &:focus,
          &:hover {
            border-color: lighten($darkBlackColor, 15%) !important;
          }
        }
      }
    }
  }

  .actions-wrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    .publish-form {
      margin-right: 2rem;
    }
  }

  /deep/ {
    .field-main-item {
      margin-bottom: 0;
    }
  }
}

.tokens-wrapper {
  display: table;
  margin-bottom: 0.5rem;
  font-size: $smallerFontSize;
  font-weight: $mediumFontWeight;
  background-color: darken($whiteColor, 2.5%);
  border: 1px solid darken($whiteColor, 8.5%);
  color: lighten($darkBlackColor, 10%);
  padding: 0.35rem 0.5rem;
  border-radius: 0.35rem;

  &.red {
    color: $redMessageTextColor;
    background-color: $redMessageColor;
    border-color: $redMessageBorderColor;
  }

  &.yellow {
    color: $warningYellowColor;
    background-color: $warningYellowBackgroundColor;
    border-color: $warningYellowBorderColor;
  }
}

.article-body {
  position: relative;
  overflow-y: scroll;
  margin-bottom: 3.5rem;
  flex: 1;
  .chunk-block {
    position: relative;
    margin: 0 20% 0;
    padding-top: 1rem;
    border-top: 2px dashed darken($greyBorderColor, 25%);

    &:first-child {
      padding-top: 0;
      border-top: none;

      .chunk-actions {
        margin-top: 0;
      }
    }

    .chunk-actions {
      position: absolute;
      left: calc(100% + 2rem);
      top: 2rem;
      white-space: nowrap;
      margin-top: 1rem;

      .chunk-index {
        display: block;
        font-size: $smallestFontSize;
        color: $greyColor;
        margin-bottom: 0.5rem;
        opacity: $lightOpacity;
      }
    }

    .article-form /deep/ {
      .ql-toolbar {
        display: none !important;
      }

      #quill-container {
        border-radius: 1rem !important;
        background-color: $whiteColor;
        border: none;
        padding-top: 2rem;
        width: auto !important;
        max-height: none;

        * {
          color: $darkBlackColor !important;
          font-size: 1rem;
          font-weight: 400;
        }

        .ql-editor {
          padding: 0;

          &.ql-blank::before {
            left: 0;
          }
        }
      }
    }
  }
}

.article-actions-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0rem;
  margin: 0.5rem auto;
  text-align: center;

  .btn {
    box-shadow: 0 0.25 1rem 0.15rem $boxShadowColor;
  }
}

.show-shadow {
  box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
}

/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}

.pre-loader-wrapper {
  margin: 5rem 15% 0;

  /deep/ {
    .buffer-line {
      width: auto;
    }
  }
}
</style>