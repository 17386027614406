<template>
  <div class="modal-header" :class="[options.color, options.size]">
    <!--START: Title Wrapper-->
    <div class="title-wrapper" :class="{ invert: options.hasButtons }">
      <h3>{{ title }}</h3>
      <unicon
        :name="options.hasButtons ? 'angle-left-b' : 'times'"
        @click="closeModal"
      ></unicon>
    </div>
    <!--END: Title Wrapper-->

    <!--START: Buttons Wrapper-->
    <div v-if="options.hasButtons" class="btn-wrapper">
      <button
        v-if="options.buttons.primaryButtonTitle != undefined"
        class="btn btn-small btn-primary"
        :disabled="
          options.buttons.disableButtons
            ? options.buttons.disableButtons
            : false
        "
        @click="primaryEvent"
      >
        {{ options.buttons.primaryButtonTitle }}
      </button>
      <button
        v-if="options.buttons.secondaryButtonTitle != undefined"
        class="btn btn-small btn-text"
        :class="{ ' btn-invert': options.color == 'dark' }"
        @click="secondaryEvent"
      >
        <span>{{ options.buttons.secondaryButtonTitle }}</span>
      </button>
    </div>
    <!--END: Buttons Wrapper-->
  </div>
</template>

<script>
export default {
  name: "ModalHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
    options: { type: Object, required: false, default: () => ({}) },
  },
  methods: {
    primaryEvent() {
      this.$emit("primaryEvent");
    },

    secondaryEvent() {
      this.$emit("secondaryEvent");
    },

    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped lang="scss">
.modal-header {
  display: flex;
  align-items: center;
  padding: .5rem;
  border-bottom: 1px solid $headerBorderColor;
  border-top-left-radius: 1.05rem;
  border-top-right-radius: 1.05rem;
  background-color: $lightWhiteColor;

  .title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    h3 {
      flex: 1;
      margin-right: 3rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: $smallFontSize;
      font-weight: $normalFontWeight;
      opacity: $lightOpacity;
      margin-bottom: 0;
    }

    /deep/ .unicon svg {
      fill: darken($whiteColor, 55%);
      cursor: pointer;
    }

    &.invert {
      flex-direction: row-reverse;
      width: auto;
      /deep/ .unicon svg {
        width: 1.25rem;
        height: auto;
        margin-right: 1rem;
      }
    }

    /deep/ .unicon svg {
      &:hover {
        fill: $secondaryColor;
      }
    }
  }

  &.dark {
    background-color: darken($titleColor, 7.5%);

    h3 {
      color: $whiteColor;
    }

    /deep/ .unicon svg {
      background-color: lighten($greyColor, 25%);
      fill: $whiteColor;
    }
  }
}

.btn-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex: 1;

  .btn {
    margin-left: 1rem;
  }
}
</style>