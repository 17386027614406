<template>
  <div>
    <div class="info-wrapper">
      <h3 class="section-title">Plans</h3>
    </div>

    <!--START: Pricing Tables-->
    <div class="pricing-tables">
      <div
        v-for="plan in plans"
        :key="plan._id"
        :class="{ current: plan.isCurrentPlan }"
        class="pricing-block"
      >
        <!--START: Badge-->
        <span v-if="plan.isCurrentPlan" class="current-badge"
          >Current Plan</span
        >
        <!--END: Badge-->

        <!--START: Main Details-->
        <div class="plan-details">
          <span class="title">{{ plan.title }}</span>
          <p class="description">{{ plan.description }}</p>

          <!--START: Pricing-->
          <div class="price-details">
            <span class="large-price"
              >${{ getLargePrice(plan) }} <em>per month</em></span
            >
          </div>
          <!--END: Pricing-->

          <!--START: Ticket Volumes-->
          <div class="volume-details">
            <span class="ticket-volume">
              <b>{{ plan.tickets }}</b> answers</span
            >
          </div>
          <!--END: Ticket Volumes-->

          <span
            v-if="plan.isCurrentPlan"
            class="btn btn-border btn-small btn-subscribe btn-current-plan"
            >Current Plan</span
          >
          <button
            v-else-if="showSubscriptionButton(plan)"
            class="btn btn-border btn-small btn-subscribe btn-upgrade"
            @click="subscribe(plan.planID)"
          >
            Subscribe
          </button>
        </div>
        <!--END: Main Details-->
      </div>
    </div>
    <!--END: Pricing Tables-->
  </div>
</template>
  
<script>
export default {
  name: "AvailablePlans",
  props: {
    subscription: Object,
  },
  data() {
    return {
      plans: [
        {
          title: "Free",
          description: "Give Macha AI a try and create amazing replies",
          planType: "free",
          isPaid: false,
          isSalesLed: false,
          isCurrentPlan: false,
          tickets: 50,
          pricing: {
            monthly: {
              originalPrice: 0,
            },
            annual: {
              originalPrice: 0,
              discountedPrice: 0,
            },
          },
        },
        {
          planID: "Macha-AI-Zendesk-App-49-pm-plan-USD-Monthly",
          title: "Starter",
          description: "For individual and small teams",
          planType: "starter",
          isPaid: true,
          isSalesLed: false,
          isCurrentPlan: false,
          tickets: 250,
          pricing: {
            monthly: {
              originalPrice: 49,
            },
            annual: {
              originalPrice: 49,
              discountedPrice: 49,
            },
          },
        },
        {
          planID: "Macha-AI-Zendesk-App-99-pm-plan-USD-Monthly",
          title: "Professional",
          description: "For startups and small businesses",
          planType: "professional",
          isPaid: true,
          isSalesLed: false,
          isCurrentPlan: false,
          tickets: 750,
          pricing: {
            monthly: {
              originalPrice: 99,
            },
            annual: {
              originalPrice: 99,
              discountedPrice: 99,
            },
          },
        },
        {
          planID: "Macha-AI-Zendesk-App-249-pm-plan-USD-Monthly",
          title: "Plus",
          description: "For medium and large brands",
          planType: "plus",
          isPaid: true,
          isSalesLed: false,
          isCurrentPlan: false,
          tickets: 2000,
          pricing: {
            monthly: {
              originalPrice: 249,
            },
            annual: {
              originalPrice: 249,
              discountedPrice: 249,
            },
          },
        },
      ],
      annualBillingCycle: false,
    };
  },
  computed: {
    // Determine the plan type
    planType() {
      if (this.annualBillingCycle) return "annual";
      return "monthly";
    },
  },
  created() {
    this.initSubscription();
  },
  methods: {
    // Determine if the subscription buttons should be shown
    showSubscriptionButton(plan) {
      if (plan.isPaid) return this.subscription.planType === "free";
      else return false;
    },

    // Initialise the subscriptions
    initSubscription() {
      let selectedPlan = {};
      if (this.subscription.planType === "free") {
        selectedPlan = this.plans.find(
          (p) => p.planType == this.subscription.planType
        );
        selectedPlan.isCurrentPlan = true;
      } else if (this.subscription.planType === "standard")
        selectedPlan = this.plans.find(
          (p) => p.planID == this.subscription.planID
        );

      if (this.subscription.status === "active") {
        selectedPlan.isCurrentPlan = true;
      }
    },

    // Subscribe to the plan
    subscribe(planID) {
      this.$emit("subscribePlan", planID);
    },

    // Check to see if small price needs to be shown
    hasSmallPrice(plan) {
      if (plan.pricing[this.planType].discountedPrice) return true;
      return false;
    },

    // Return the small price
    getSmallPrice(plan) {
      return plan.pricing[this.planType].originalPrice;
    },

    // Return the large price
    getLargePrice(plan) {
      if (plan.pricing[this.planType].discountedPrice)
        return plan.pricing[this.planType].discountedPrice;
      return plan.pricing[this.planType].originalPrice;
    },
  },
};
</script>
  
<style scoped lang="scss">
.page-header {
  margin-top: -0.5rem;
  margin-bottom: 2rem;
}

.info-wrapper {
  h3 {
    font-family: $titleFont;
    font-size: $largerFontSize;
    font-weight: $boldFontWeight;
    margin-bottom: 2rem;
  }
}

.pricing-wrapper {
  position: relative;
}

.pricing-toggle {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f1f1f1;
  border: 1px solid #e9e9e9;
  padding: 0.3rem 0.35rem;
  gap: 0.5rem;
  width: fit-content;
  margin: 2rem auto 3rem;
  border-radius: 0.75rem;
  z-index: 2;

  .toggle-option {
    padding: 0.35rem 1rem;
    border-radius: 0.5rem;
    color: #7d7d7d;
    font-weight: $boldFontWeight;
    font-size: 1rem;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
      color: darken(#7d7d7d, 15%);
    }

    &.selected {
      background-color: $purpleColor;
      border: 1px solid lighten($purpleColor, 35%);
      color: lighten($purpleColor, 45%);
    }
  }
}

.pricing-tables {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 0 3rem;
  gap: 1rem;
}

.pricing-block {
  position: relative;
  flex: 1;
  padding: 2rem;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.75);
  border: 1px solid #ececec;
  box-shadow: 0 0rem 1rem -0.15rem rgba(111, 110, 110, 0.15);
  backdrop-filter: blur(15px);
  transform: translate3d(0, 0, 0);

  .title {
    color: $darkBlackColor;
    font-weight: $boldFontWeight;
    font-size: $normalFontSize;
  }

  .description {
    font-size: $smallerFontSize;
    opacity: 0.75;
    margin: 0.25rem 0 1rem;
    line-height: 1.4;
    min-height: 2.5rem;
  }

  .small-price {
    font-family: $titleFont;
    color: lighten($darkBlackColor, 55%);
    font-weight: $mediumFontWeight;
    font-size: 1.5rem;
    text-decoration: line-through;
    margin-right: 0.5rem;
  }

  .large-price {
    font-family: $titleFont;
    color: $darkBlackColor;
    font-weight: $boldFontWeight;
    font-size: $mediumFontSize;
    font-size: 1.5rem;

    em {
      color: lighten($darkBlackColor, 15%);
      font-weight: $mediumFontWeight;
      font-size: $smallerFontSize;
      margin-left: 0;
    }
  }

  .btn-subscribe {
    font-size: $smallerFontSize;
    display: block;
    padding: 0.5rem;
    width: 100%;
    margin: 1rem auto 0;

    &.btn-upgrade {
      background: $darkBlackColor;
      border-color: lighten($darkBlackColor, 15%);
      color: $whiteColor;
    }

    &:hover {
      background: $purpleColor;
      border-color: lighten($purpleColor, 15%);
      color: $whiteColor;
    }

    &.btn-current-plan {
      width: calc(100% - 1rem);
      background: #f2fae9 !important;
      color: #5a876f !important;
      border-color: #adcebd !important;
    }
  }

  .volume-details {
    margin-top: 0;
    margin-bottom: 0.5rem;

    span {
      display: block;
      color: lighten($darkBlackColor, 35%);

      &.ticket-volume {
        font-size: 0.9rem;
        color: $purpleColor;
      }

      &.billing-cycle {
        margin-top: 1.5rem;
      }
    }
  }

  &.current {
    border: 2px solid $emeraldColor;
    background: rgba(250, 255, 241, 0.55);
  }
}

.inclusions-list {
  .title {
    display: block;
    color: $darkBlackColor;
    font-weight: $boldFontWeight;
    font-size: $normalFontSize;
    margin-top: 3.5rem;
  }
  .feature {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 0;

    :deep(svg) {
      fill: $emeraldColor;
      width: auto;
      height: 1.15rem;
      margin-right: 1rem;
    }

    span {
      color: lighten($darkBlackColor, 35%);
    }
  }
}

.current-badge {
  position: absolute;
  color: lighten($emeraldColor, 75%);
  background-color: $emeraldColor;
  padding: 0.25rem 1rem;
  border-radius: 1rem;
  font-size: $smallerFontSize;
  font-weight: $mediumFontWeight;
  top: -1rem;
  left: 1.5rem;
}

.features-toggle {
  display: none;
}
</style>