<template>
  <div v-if="showComponent" class="authentication-component">
    <!--START: Vue Headful-->
    <vue-headful :title="meta.title" />
    <!--END: Vue Headful-->

    <div class="authentication-wrapper">
      <div class="card">
        <!--START: Illustration-->
        <img
          src="@/assets/images/doodles/welcome.png"
          class="card-illustration"
          alt="Welcome to Macha"
        />
        <!--END: Illustration-->

        <!--START: Intro-->
        <div class="intro-wrapper">
          <img src="@/assets/images/logo.png" class="logo" alt="Macha Logo" />
          <h1>Great! Let's get started</h1>
          <p>Enter the e-mail address that you want to use</p>
        </div>
        <!--END: Intro-->

        <!--START: Status Message-->
        <StatusMessage
          :show="status.show"
          :status="status.status"
          :message="status.message"
        ></StatusMessage>
        <!--END: Status Message-->

        <!--START: Form Wrapper-->
        <form v-on:submit.prevent="registerUser()">
          <FormBuilder
            :fields="fields"
            @fieldChanged="fieldChanged()"
          ></FormBuilder>
          <button type="submit" class="btn btn-primary">
            <span>Create Account</span>
            <unicon name="angle-right-b"></unicon>
          </button>
        </form>
        <!--END: Form Wrapper-->

        <!--START: Line Loader-->
        <LineLoader :show="showLoader" additionalClass="bottom"></LineLoader>
        <!--END: Line Loader-->
      </div>

      <!--START: Alternate Option-->
      <div class="alternate-option">
        <p>Already have an account?</p>
        <router-link to="/sign-in">Sign in to your account</router-link>
      </div>
      <!--END: Alternate Option-->
    </div>
  </div>
</template>

<script>
//Importing services
import { AuthService } from "@/services";

//Importing components
import LineLoader from "@/components/loaders/LineLoader";
import FormBuilder from "@/components/form/FormBuilder";
import StatusMessage from "@/components/modals/StatusMessage";

export default {
  name: "RegisterUser",
  data() {
    return {
      showComponent: false,
      showLoader: false,
      meta: {
        title: "Sign Up",
      },
      status: {
        show: false,
        status: null,
        message: null,
      },
      fields: {
        email: {
          type: "email",
          placeholder: "E-mail",
          required: true,
          hasError: false,
          value: "",
        },
      },
    };
  },
  components: {
    LineLoader,
    FormBuilder,
    StatusMessage,
  },
  async created() {
    if (!(await this.authenticateUser())) this.showComponent = true;
  },
  methods: {
    async registerUser() {
      // Hide status message and show line loader
      this.status.show = false;
      this.showLoader = true;

      // Check if the form has valid input
      var isFormValid = this.validateForm(this.fields);
      if (isFormValid) {
        const data = {
          ...this.parseData(this.fields),
        };
        // Use the service to register the user
        const response = await AuthService.RegisterUser(data);
        if (response.hasError) {
          this.status.status = "error";
          this.status.message = response.message;
        } else if (!response.hasError) {
          this.status.status = "success";
          this.status.message = response.message;
          this.redirectOnboarding(response.data);
          this.resetFields(this.fields);
        }
        this.status.show = true;
      }
      // Hide line loader
      this.showLoader = false;
    },

    redirectOnboarding(data) {
      // Add userto VueEx
      this.$store.commit("updateUser", { user: data.user, loggedIn: true });

      // Redirect to onboarding
      this.$router.push({ path: "/onboarding" });
    },

    fieldChanged() {
      this.status.show = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
@import "@/assets/styles/shared/authentication.scss";
</style>