import { render, staticRenderFns } from "./AnswerRules.vue?vue&type=template&id=218e06e1&scoped=true"
import script from "./AnswerRules.vue?vue&type=script&lang=js"
export * from "./AnswerRules.vue?vue&type=script&lang=js"
import style0 from "./AnswerRules.vue?vue&type=style&index=0&id=218e06e1&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "218e06e1",
  null
  
)

export default component.exports