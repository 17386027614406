<template>
  <div class="page-loader-wrapper" :class="{ show: showLoader }">
    <div class="page-loader"></div>
    <span>
      <b>Loading</b>This should take a second
    </span>
  </div>
</template>

<script>
export default {
  name: "PageLoader",
  props: {
    showLoader: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
$loaderColor: $purpleColor;

.page-loader-wrapper {
  display: none;
  text-align: center;

  &.show {
    display: block;
  }
}

span {
  color: $greyColor;
  display: block;
  margin-top: 2rem;

  b {
    display: block;
    font-size: $mediumFontSize;
    margin-bottom: 0.5rem;
  }
}

.page-loader {
  height: 0.1em;
  width: 0.1em;
  margin: auto;
  box-shadow: -0.2em -0.2em 0 0.1em $loaderColor,
    -0.2em -0.2em 0 0.1em $loaderColor, -0.2em -0.2em 0 0.1em $loaderColor,
    -0.2em -0.2em 0 0.1em $loaderColor;
  animation: pageLoader 4.5s infinite;
  transform: scale(2.5);
}

@keyframes pageLoader {
  0% {
    box-shadow: -0.2em -0.2em 0 0.1em $loaderColor,
      -0.2em -0.2em 0 0.1em $loaderColor, -0.2em -0.2em 0 0.1em $loaderColor,
      -0.2em -0.2em 0 0.1em $loaderColor;
  }
  8.33% {
    box-shadow: -0.2em -0.2em 0 0.1em $loaderColor,
      0.2em -0.2em 0 0.1em $loaderColor, 0.2em -0.2em 0 0.1em $loaderColor,
      0.2em -0.2em 0 0.1em $loaderColor;
  }
  16.66% {
    box-shadow: -0.2em -0.2em 0 0.1em $loaderColor,
      0.2em -0.2em 0 0.1em $loaderColor, 0.2em 0.2em 0 0.1em $loaderColor,
      0.2em 0.2em 0 0.1em $loaderColor;
  }
  24.99% {
    box-shadow: -0.2em -0.2em 0 0.1em $loaderColor,
      0.2em -0.2em 0 0.1em $loaderColor, 0.2em 0.2em 0 0.1em $loaderColor,
      -0.2em 0.2em 0 0.1em $loaderColor;
  }
  33.32% {
    box-shadow: -0.2em -0.2em 0 0.1em $loaderColor,
      0.2em -0.2em 0 0.1em $loaderColor, 0.2em 0.2em 0 0.1em $loaderColor,
      -0.2em -0.2em 0 0.1em $loaderColor;
  }
  41.65% {
    box-shadow: 0.2em -0.2em 0 0.1em $loaderColor,
      0.2em -0.2em 0 0.1em $loaderColor, 0.2em 0.2em 0 0.1em $loaderColor,
      0.2em -0.2em 0 0.1em $loaderColor;
  }
  49.98% {
    box-shadow: 0.2em 0.2em 0 0.1em $loaderColor,
      0.2em 0.2em 0 0.1em $loaderColor, 0.2em 0.2em 0 0.1em $loaderColor,
      0.2em 0.2em 0 0.1em $loaderColor;
  }
  58.31% {
    box-shadow: -0.2em 0.2em 0 0.1em $loaderColor,
      -0.2em 0.2em 0 0.1em $loaderColor, 0.2em 0.2em 0 0.1em $loaderColor,
      -0.2em 0.2em 0 0.1em $loaderColor;
  }
  66.64% {
    box-shadow: -0.2em -0.2em 0 0.1em $loaderColor,
      -0.2em -0.2em 0 0.1em $loaderColor, 0.2em 0.2em 0 0.1em $loaderColor,
      -0.2em 0.2em 0 0.1em $loaderColor;
  }
  74.97% {
    box-shadow: -0.2em -0.2em 0 0.1em $loaderColor,
      0.2em -0.2em 0 0.1em $loaderColor, 0.2em 0.2em 0 0.1em $loaderColor,
      -0.2em 0.2em 0 0.1em $loaderColor;
  }
  83.3% {
    box-shadow: -0.2em -0.2em 0 0.1em $loaderColor,
      0.2em 0.2em 0 0.1em $loaderColor, 0.2em 0.2em 0 0.1em $loaderColor,
      -0.2em 0.2em 0 0.1em $loaderColor;
  }
  91.63% {
    box-shadow: -0.2em -0.2em 0 0.1em $loaderColor,
      -0.2em 0.2em 0 0.1em $loaderColor, -0.2em 0.2em 0 0.1em $loaderColor,
      -0.2em 0.2em 0 0.1em $loaderColor;
  }
  100% {
    box-shadow: -0.2em -0.2em 0 0.1em $loaderColor,
      -0.2em -0.2em 0 0.1em $loaderColor, -0.2em -0.2em 0 0.1em $loaderColor,
      -0.2em -0.2em 0 0.1em $loaderColor;
  }
}
</style>