<template>
  <div class="key-metrics">
    <!--START: Instructions-->
    <div class="stat-item">
      <b class="stat">{{ getTotalCount("generateEmailByInstruction") }}</b>
      <div class="stat-label">
        <span>Instructions</span>
        <div class="stat-info">
          <span>i</span>
          <p>Instructions given to the AI to create an answer</p>
        </div>
      </div>
    </div>
    <!--END: Instructions-->

    <!--START: AI Answers-->
    <div class="stat-item">
      <b class="stat">{{ getTotalCount("generateAIAnswer") }}</b>
      <div class="stat-label">
        <span>AI Answers</span>
        <div class="stat-info">
          <span>i</span>
          <p>Answers generated by the AI using the knowledge base</p>
        </div>
      </div>
    </div>
    <!--END: AI Answers-->

    <!--START: Macros-->
    <div class="stat-item">
      <b class="stat">{{ getTotalCount("invokeMacro") }}</b>
      <div class="stat-label">
        <span>Macros</span>
        <div class="stat-info">
          <span>i</span>
          <p>The amount of times a suggested macro was used</p>
        </div>
      </div>
    </div>
    <!--END: Macros-->

    <!--START: Added to Editor-->
    <div class="stat-item">
      <b class="stat">{{ getTotalCount("addResponseToEditor") }}</b>
      <div class="stat-label">
        <span>Added to Editor</span>
        <div class="stat-info">
          <span>i</span>
          <p>The number of answers added to the Zendesk editor</p>
        </div>
      </div>
    </div>
    <!--END: Added to Editor-->
  </div>
</template>

<script>
export default {
  name: "KeyMetrics",
  props: {
    insights: { type: Array, required: true },
  },
  data() {
    return {};
  },
  components: {},
  computed: {},
  async created() {},
  methods: {
    // Get total count by event name
    getTotalCount(eventName) {
      if (this.insights) {
        let count = 0;
        const answers = this.insights.find((i) => i.event === eventName);

        answers?.agents.forEach((agent) => {
          agent.monthlyAnalytics.forEach((month) => {
            count += month.count;
          });
        });

        return count;
      }

      return 0;
    },
  },
};
</script>
  
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.key-metrics {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.stat {
  margin-left: -0.5rem;
}
.stat-item {
  margin-left: 2.5rem;
  padding-left: 2.5rem;
  border-left: 1px solid $greyBorderColor;

  &:first-child {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  }

  &:last-child {
    .stat-info p {
      left: auto;
      right: 0;
      transform: none;
    }
  }
}
</style>