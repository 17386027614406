import { render, staticRenderFns } from "./SubscriptionBanner.vue?vue&type=template&id=960223ba&scoped=true"
import script from "./SubscriptionBanner.vue?vue&type=script&lang=js"
export * from "./SubscriptionBanner.vue?vue&type=script&lang=js"
import style0 from "./SubscriptionBanner.vue?vue&type=style&index=0&id=960223ba&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "960223ba",
  null
  
)

export default component.exports