<template>
  <div class="app-body">
    <!--START: Title-->
    <div
      class="title-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <div class="title-info">
        <h3 class="title">Connect Zendesk</h3>
        <p>Add functionality to the AI to raise tickets, handoff to agents</p>
      </div>
      <div class="actions-wrapper">
        <button class="btn btn-small btn-primary" @click="addApp">
          Continue
        </button>
      </div>
    </div>
    <!--END: Title-->

    <!--START: Form-->
    <div class="scroll-body" ref="formBody" @scroll="bodyScroll">
      <!--START:  Fields Options-->
      <SettingsForm
        v-for="field in fields"
        :key="field._id"
        :fields="field.fields"
        :content="field.content"
        additionalClass="two-column-form"
      ></SettingsForm>
      <!--END:  Fields Options-->
    </div>
    <!--END: Form-->
  </div>
</template>
  
<script>
// Importing components
import SettingsForm from "@/components/form/SettingsForm";

export default {
  name: "AddZendeskForm",
  data() {
    return {
      showOptions: {
        headerShadow: false,
      },
      fields: {
        app: {
          content: {
            title: "Zendesk Authentication",
            description: "Add your Zendesk credentials to connect to Macha",
          },
          fields: {
            domainName: {
              type: "text",
              placeholder: "Your domain name",
              required: false,
              hasError: false,
              title: "Domain name*",
              value: "",
            },
          },
        },
      },
    };
  },
  props: {},
  watch: {},
  components: {
    SettingsForm,
  },
  computed: {},
  async created() {},
  mounted() {},
  methods: {
    // Add the site
    addApp() {
      // Check if the form has valid input
      const data = this.fields.app.fields;
      var isFormValid = this.validateForm(data);
      if (isFormValid) {
        const payload = this.constructPayload(data);
        this.$emit("addApp", payload);
      }
    },

    // Construct the payload
    constructPayload(data) {
      const payload = {
        appName: "zendesk",
        credentials: this.parseData(data),
      };

      return payload;
    },

    // Event on body scroll
    bodyScroll() {
      const scrollTop = this.$refs.formBody.scrollTop;

      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },
  },
};
</script>
  
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
</style>