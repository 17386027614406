<template>
  <div class="success-form">
    <unicon name="times"></unicon>
    <div class="success-info-wrapper">
      <h3>Zendesk Integration Failed</h3>
      <p>
        Something went wrong! Reach out to support at hello@getmacha.com to get
        the team to fix this for you.
      </p>
      <div class="success-btn-wrapper">
        <button
          type="button"
          class="btn btn-primary btn-small"
          @click="gotoDashboard"
        >
          Goto Macha Dashboard
        </button>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "OauthError",
  data() {
    return {};
  },
  components: {},
  methods: {
    gotoDashboard() {
      window.open(
        `${process.env.VUE_APP_API_URL}/settings/integrations`,
        "_blank"
      );
      window.setTimeout(function () {
        this.close();
      }, 0);
    },
  },
};
</script>
    
<style scoped lang="scss">
.success-form {
  margin-top: 3rem;
  flex-direction: row;
  text-align: center;
}

.success-form.show {
  display: block;
}

.success-form .unicon /deep/ svg {
  display: block;
  align-items: center;
  fill: darken($redColor, 30%);
  stroke: darken($redColor, 30%);
  background-color: lighten($redColor, 15%);
  border: 10px solid lighten($redColor, 25%);
  padding: 0.75rem;
  width: 2rem;
  height: auto;
  border-radius: 50%;
  margin-bottom: 1rem;
  path {
    stroke-width: 2;
  }
}

.success-info-wrapper {
  h3 {
    font-size: $largeFontSize;
  }
  p {
    font-size: $smallFontSize;
    color: $greyColor;
  }
}

.success-btn-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 0;
  .btn {
    margin-right: 0.75rem;
  }
}
</style>