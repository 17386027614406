require("dotenv").config();
import axios from "axios";

//Importing Variable mixins
const apiURL = `${process.env.VUE_APP_API_URL}`;

class IntegrationService {
  // Add Gorgias app
  static InstallGorgiasWidget() {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .get(`${apiURL}/gorgias/install-widget`, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Get Zendesk ticket fields
  // Get all ticket fields
  static GetTicketFields() {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .get(`${apiURL}/zendesk/ticket-fields`, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }
}

export default IntegrationService;
