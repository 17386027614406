require("dotenv").config();
import axios from "axios";

const apiURL = `${process.env.VUE_APP_API_URL}/auth`;

class AuthService {
  // Service to register a new user
  static RegisterUser(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/register`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.error("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Service to get OTP
  static GetEmailOTP(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/get-email-otp`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.error("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Service to validate OTP
  static ValidateEmailOTP(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/validate-email-otp`, fields, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.error("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Service to select company
  static SelectCompany(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/select-company`, fields, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.error("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Service to toggle company
  static ToggleCompany(fields) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/toggle-company`, fields, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.error("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Service to logout user
  static LogoutUser() {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .get(`${apiURL}/logout`, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.error("err", e);
            reject(e);
          }
        })()
      );
    });
  }
}

export default AuthService;
