<template>
  <div v-if="show">
    <!--START: Shopify App-->
    <div class="modal modal-large" :class="[{ show: show }]">
      <!--START: Header-->
      <ModalHeader :title="title" @closeModal="closeModal"></ModalHeader>
      <!--END: Header-->

      <!--START: Modal Body-->
      <div class="modal-body">
        <!--START: Title-->
        <div
          class="title-wrapper"
          :class="{ 'show-shadow': showOptions.headerShadow }"
        >
          <div class="title-info">
            <h3 class="title">Add User</h3>
            <p>Add users to your team and give them dashboard access</p>
          </div>
          <div class="actions-wrapper">
            <button class="btn btn-small btn-primary" @click="addUser">
              Add User
            </button>
          </div>
        </div>
        <!--END: Title-->

        <div class="scroll-body">
          <SettingsForm
            v-for="field in fields"
            :key="field._id"
            :fields="field.fields"
            :content="field.content"
            additionalClass="two-column-form"
          ></SettingsForm>
        </div>
      </div>
      <!--END: Modal Body-->

      <!--START: Notification Message-->
      <NotificationMessage
        :show="status.show"
        :status="status.status"
        :title="status.title"
        @closeModal="closeModal"
      ></NotificationMessage>
      <!--END: Notification Message-->

      <!--START: Loader-->
      <LineLoader
        :show="showOptions.lineLoader"
        :class="{ bottom: !showOptions.modalActions }"
      ></LineLoader>
      <!--END: Loader-->
    </div>
    <!--END: Shopify App-->

    <div class="bg-overlay light show" @click="closeModal"></div>
  </div>
</template>
          
<script>
// Import libraries
import _ from "lodash";

// Importing components
import ModalHeader from "@/components/modals/ModalHeader";
import LineLoader from "@/components/loaders/LineLoader";
import NotificationMessage from "@/components/modals/NotificationMessage";
import SettingsForm from "@/components/form/SettingsForm";

// Importing Services
import { UserService } from "@/services";

export default {
  name: "AddUserModal",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  watch: {
    show: {
      immediate: true,
      handler() {
        this.initUser();
      },
    },
  },
  computed: {},
  data() {
    return {
      title: "Add User",
      waitingForResponse: false,
      fields: {
        user: {
          content: {
            title: "User Details",
            description: "Add details for the user",
          },
          fields: {
            name: {
              type: "text",
              placeholder: "Add the full name",
              required: true,
              hasError: false,
              title: "Full name*",
              value: "",
            },
            email: {
              type: "email",
              placeholder: "Add the e-mail address",
              required: true,
              hasError: false,
              title: "E-Mail address*",
              value: "",
            },
            role: {
              type: "dropdown",
              placeholder: "Select role",
              title: "User role",
              required: true,
              hasError: false,
              fields: [
                { name: "Agent", value: "agent" },
                { name: "Admin", value: "admin" },
              ],
              value: [],
            },
          },
        },
      },
      showOptions: {
        lineLoader: false,
        showShadow: false,
        preLoader: true,
      },
      status: {
        show: false,
        status: "success",
        title: "Users added",
      },
    };
  },
  components: {
    ModalHeader,
    LineLoader,
    NotificationMessage,
    SettingsForm,
  },
  created() {},

  methods: {
    // Check if user has to be edited
    initUser() {
      const { name, email, role } = this.fields.user.fields;
      if (!_.isEmpty(this.user)) {
        name.value = this.user.name;
        email.value = this.user.email;
        email.disabled = true;
        role.value = [this.toTitleCase(this.user.role), this.user.role];
      } else {
        name.value = "";
        email.value = "";
        email.disabled = false;
        role.value = [];
      }
    },

    // Add the user to the team
    async addUser() {
      if (!this.waitingForResponse) {
        this.waitingForResponse = true;
        this.showOptions.lineLoader = true;

        const data = this.fields.user.fields;
        var isFormValid = this.validateForm(data);
        if (isFormValid) {
          let payload = { users: [this.parseData(data)] };

          let response;
          if (_.isEmpty(this.user)) {
            response = await UserService.AddUser(payload);
          } else {
            payload = { userID: this.user._id, ...this.parseData(data) };
            response = await UserService.UpdateUser(payload);
          }

          // Check for errors
          if (!response.hasError) {
            this.showOptions.state = "success";
            this.$emit("userAdded");
          } else this.showErrorMessage(this.status, response.message);
        }

        this.waitingForResponse = false;
        this.showOptions.lineLoader = false;
      }
    },

    // Hide modal
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
          
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

/deep/ .modal-header {
  padding: 0.5rem 1rem;

  /deep/ .unicon svg {
    background-color: lighten($blackColor, 15%);
    fill: $whiteColor;
  }
}

/deep/ .status-message {
  position: relative;
  bottom: auto;
  right: 0;
  left: 0;
  border-radius: 0;
  text-align: center;
}

/deep/ .line-loader {
  position: relative;
  bottom: auto;
  right: 0;
  left: 0;
}

.pre-loader-wrapper {
  margin: 2rem 3rem;
}

.modal {
  &.show-success {
    width: auto;
  }

  .modal-body /deep/ {
    padding: 0 !important;
    overflow-y: hidden;

    .settings-form .field-main-item .field-wrapper {
      min-width: 50%;
    }

    .settings-form {
      .field-main-item input[disabled] {
        color: $greyColor;

        &:hover {
          border: 1px solid $inputBorderColor !important;
        }
      }
    }
    .title-wrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1.5rem 0.5rem;
      margin: 0 1.5rem;
      border-bottom: 1px solid $inputBorderColor;
      z-index: 5;

      .title-info {
        flex: 1;
        margin-right: 3rem;
        .title {
          font-size: $mediumFontSize;
        }
        p {
          font-size: $smallFontSize;
          color: $paragraphColor;
        }
      }
    }

    .scroll-body {
      flex: 1;
      overflow-y: scroll;
      padding: 2rem 2rem 1rem;
    }

    .show-shadow {
      box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
    }
  }
}

.success-form {
  margin: 2rem 3rem;
}
</style>