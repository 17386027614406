<template>
  <div>
    <!--START: Task List-->
    <div class="modal modal-large show">
      <!--START: Header-->
      <ModalHeader :title="title" @closeModal="closeModal"></ModalHeader>
      <!--END: Header-->

      <!--START: Modal Body-->
      <div class="modal-body">
        <div class="title-wrapper">
          <div class="video-tutorial">
            <a
              href="https://www.loom.com/share/6e4398276eef4075a1464efe6e5b7ff5"
              target="_blank"
            >
              <unicon name="play"></unicon>
              <img
                src="@/assets/images/tutorial.jpg"
                class="video-thumbnail"
                alt="Video thumbnail"
              />
            </a>
            <span>Watch the tutorial</span>
          </div>
          <h2>Get started with a few simple steps</h2>
          <p>
            You should be up and ready to use Macha in less than 10 minutes!
          </p>
        </div>

        <div class="steps-wrapper">
          <label>Getting Started</label>
          <div class="step-item" :class="{ completed: answerGenerated }">
            <span class="index">1</span>
            <div class="details">
              <h3>Install Macha widget on Zendesk</h3>
              <p>This will give all your agents access to the Macha widget</p>
              <a
                href="https://www.zendesk.com/in/marketplace/apps/support/986669/macha-ai-support/"
                target="_blank"
                class="btn btn-small btn-border"
                >Download widget for Zendesk</a
              >
            </div>
          </div>
          <div class="step-item" :class="{ completed: accountIntegrated }">
            <span class="index">2</span>
            <div class="details">
              <h3>Connect Zendesk Account</h3>
              <p>
                Use oAuth to connect Macha to Zendesk and activate your account
              </p>
              <span
                class="btn btn-small btn-border"
                @click="gotoLink('/settings/integrations')"
                >Integrate Zendesk account</span
              >
            </div>
          </div>
          <div class="step-item" :class="{ completed: accountIntegrated }">
            <span class="index">3</span>
            <div class="details">
              <h3>Voila! You're all set</h3>
              <p>Your Zendesk article and macros are now synced</p>
            </div>
          </div>
        </div>

        <!--START: Loader-->
        <LineLoader :show="showOptions.lineLoader"></LineLoader>
        <!--END: Loader-->
      </div>
      <!--END: Modal Body-->
    </div>
    <!--END: Task List-->

    <div class="bg-overlay light show" @click="closeModal"></div>
  </div>
</template>
    
<script>
// Importing components
import ModalHeader from "@/components/modals/ModalHeader";
import LineLoader from "@/components/loaders/LineLoader";

export default {
  name: "TaskList",
  computed: {
    // Get the company
    company() {
      return this.$store.getters.company;
    },

    // Get the current subscription
    subscription() {
      return this.company?.subscription;
    },

    // Check if an answer has been generated
    answerGenerated() {
      return this.subscription.usage.generatedAnswers;
    },

    // Check if Zendesk account is integrated
    accountIntegrated() {
      const { zendesk } = this.company.integrations;
      if (zendesk.credentials.domainName) return true;
      return false;
    },

    // Check if sources added to knowledge base
    sourcesAdded() {
      if (this.company.sources.length) return true;
      return false;
    },
  },
  data() {
    return {
      freePlanLimit: 50,
      title: "Welcome to Macha",
      showOptions: {
        lineLoader: false,
      },
    };
  },
  components: {
    ModalHeader,
    LineLoader,
  },
  methods: {
    // Navigate the link
    async gotoLink(link) {
      this.showOptions.lineLoader = true;
      this.$router.push({ path: link });
      await this.closeModal();
    },

    // Hide modal
    async closeModal() {
      await this.$emit("closeModal");
    },
  },
};
</script>
    
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.modal.modal-large {
  width: max-content;
  max-width: 80vw;
  background: $whiteColor;
  overflow-x: hidden;
}

.modal-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2.5rem 2rem !important;
}

.title-wrapper {
  margin: 0 2rem;
  text-align: center;
  max-width: 20rem;

  .asset {
    display: block;
    width: 12.5rem;
    margin: 0 auto 1.5rem;
  }

  h2 {
    font-size: $mediumFontSize;
  }

  p {
    color: $greyColor;
    font-size: $smallFontSize;
  }
}

.steps-wrapper {
  label {
    display: block;
    margin-left: 4rem;
    margin-bottom: 1.25rem;
    font-size: $smallFontSize;
    font-weight: $mediumFontWeight;
    opacity: 0.5;
  }
}

.step-item {
  position: relative;
  box-shadow: none;
  margin-left: 8rem;
  margin-bottom: 2rem;

  &:not(:last-child)::before {
    content: "";
    position: absolute;
    left: -3rem;
    top: 0;
    height: calc(100% + 2rem);
    width: 1px;
    border-left: 1px dashed darken($headerBorderColor, 10%);
  }

  &:last-child {
    margin-bottom: 0;
  }

  .index {
    position: absolute;
    left: -4rem;
    top: 0;
    background-color: lighten($purpleColor, 40.5%);
    color: $purpleColor;
    border: 1px solid lighten($purpleColor, 15%);
    padding: 0.5rem 0.75rem;
    border-radius: 50%;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1rem;
  }

  .details {
    h3 {
      font-size: $normalFontSize;
    }

    p {
      font-size: $smallFontSize;
      opacity: $lightOpacity;
    }

    .btn {
      font-size: $smallerFontSize;
      margin-top: 1rem;

      &:hover {
        background-color: $darkBlackColor;
        color: $whiteColor;
      }
    }
  }

  &.completed {
    .index {
      background-color: $emeraldColor;
      color: lighten($emeraldColor, 45%);
      border: 1px solid lighten($emeraldColor, 15%);
    }

    .details .btn {
      display: none;
    }
  }
}

/deep/ .line-loader {
  left: 0;
}

.video-tutorial {
  margin-bottom: 1rem;

  span {
    display: block;
    font-size: $smallerFontSize;
    color: darken($whiteColor, 45%);
    margin-top: 0.5rem;
  }

  a {
    display: inline-block;
    position: relative;

    img {
      width: 15.5rem;
      border-radius: 1rem;
      border: 1px solid #ececec;
    }

    .unicon /deep/ svg {
      position: absolute;
      top: 50%;
      left: 50%;
      fill: $whiteColor;
      transform: translate(-50%, -50%);
      background: rgba(255, 255, 255, 0.15);
      padding: 0.5rem;
      border-radius: 50%;
      border: 1px solid rgba(248, 248, 248, 0.1);
      backdrop-filter: blur(10px);
    }
  }
}
</style>