<template>
  <div class="field-dropdown-wrapper">
    <div
      class="dropdown-info-wrapper"
      :disabled="field.disabled != undefined ? field.disabled : false"
    >
      <label
        class="field-placeholder field-placeholder-value"
        v-if="field.value != ''"
        >{{ field.value[0] }}</label
      >
      <label class="field-placeholder" v-else>{{ field.placeholder }}</label>
      <unicon class="field-icon" name="angle-down"></unicon>
      <select
        v-model="field.value"
        :disabled="field.disabled != undefined ? field.disabled : false"
        @change="fieldChanged"
      >
        <option
          v-for="item in field.fields"
          :key="item._id"
          :value="[item.name, item.value]"
        >
          {{ item.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectDropdown",
  data() {
    return {
      showOptions: {
        dropdownWrapper: false,
      },
    };
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  methods: {
    fieldChanged() {
      this.$emit("fieldChanged");
    },
  },
};
</script>

<style scoped lang="scss">
.dropdown-info-wrapper {
  position: relative;
  cursor: pointer;
  transition: all $transitionSpeed;
  display: block;
  font-size: 1rem;
  font-weight: 500;
  outline: none;
  background-color: $whiteColor;
  color: $inputTextColor;
  width: calc(100% - 2.5rem);
  padding: 0.75rem 1.15rem;
  border: 1px solid $inputBorderColor;
  border-radius: 0.5rem;
  -webkit-appearance: none;

  &::placeholder {
    font-size: $smallFontSize;
    font-weight: 400;
    color: lighten($darkBlackColor, 50%);
  }
}

.field-placeholder {
  display: block;
  cursor: pointer;
  font-size: $normalFontSize;
  line-height: 1.5;
  font-weight: 400;
  color: lighten($greyColor, 25%);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
}

.field-placeholder-value {
  color: $darkBlackColor !important;
  font-weight: $mediumFontWeight !important;
}

.field-icon {
  @include vertical-center-align;
  margin-top: 0.1rem;
  right: 0.5rem;

  /deep/ svg {
    fill: $cherryRedColor;
  }
}

.dropdown-info-wrapper:hover {
  border-color: $secondaryColor;
}

.error .dropdown-info-wrapper {
  border-color: $redColor !important;
}

select {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  outline: none;
  border: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dropdown-info-wrapper[disabled] {
  background: rgba(239, 239, 239, 0.3);
  
  .field-icon {
    display: none;
  }

  .field-placeholder {
    width: auto;
  }
}
</style>