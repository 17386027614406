<template>
  <div>
    <!--START: Status Message-->
    <div
      v-if="show"
      class="status-message"
      :class="[
        { show: show },
        status,
        additionalClass,
        { 'large-modal': isLargeModal },
      ]"
    >
      <img
        v-if="isLargeModal"
        src="@/assets/images/doodles/modal-success.jpg"
        alt="Modal Success"
      />
      <h3 v-if="isLargeModal && title">{{ title }}</h3>
      <span>{{ message }}</span>
      <button v-if="isLargeModal" type="button" class="btn btn-small btn-border btn-narrow" @click="closeModal">Close this</button>
    </div>
    <!--END: Status Message-->

    <!--START: BG Overlay-->
    <div
      v-if="show && isLargeModal"
      class="bg-overlay show"
      @click="closeModal"
    ></div>
    <!--START: BG Overlay-->
  </div>
</template>

<script>
export default {
  name: "StatusMessage",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    status: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    message: {
      type: String,
      required: false,
    },
    isLargeModal: {
      type: Boolean,
      required: false,
    },
    additionalClass: {
      type: String,
      required: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.status-message {
  display: none;
  background-color: $greenMessageColor;
  border: 1px solid $greenMessageBorderColor;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;

  span {
    font-size: $smallFontSize;
    font-weight: $mediumFontWeight;
    color: $greenMessageTextColor;
    line-height: 1rem;
  }

  &.show {
    display: block;
  }

  &.error {
    background-color: $redMessageColor;
    border: 1px solid $redMessageBorderColor;

    span {
      color: $redMessageTextColor;
    }
  }

  &.fixed {
    position: fixed;
    z-index: 15;
    top: 4.5rem;
    left: 0;
    right: 0;
    text-align: center;
  }

  &.modal-fixed-bottom {
    top: auto;
    bottom: 4.5rem;
    border-radius: 0;
  }

  &.large-modal {
    background-color: $whiteColor;
    border-color: $inputBorderColor;
    border-radius: 1.5rem;
    top: 50%;
    left: 50%;
    right: auto;
    margin: auto;
    padding: 1.5rem 3rem;
    width: 20rem;
    transform: translate(-50%, -50%);
    z-index: 101;

    img {
      width: 12.5rem;
      margin-bottom: 0.5rem;
    }

    span {
      display: block;
      font-weight: $normalFontWeight;
      font-size: $normalFontSize;
      color: $greyColor;
      line-height: 1.4;
      margin-bottom: 0.5rem;
    }
  }
}

.btn {
 width: 100%;
 margin-top: 1rem;
}

.bg-overlay {
  cursor: pointer;
}
</style>