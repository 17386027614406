// Importing moment.js for time based functions
import moment from "moment-timezone";

// Get the last message time
const getLastMessageTime = (timestamp) => {
  let time = "";
  timestamp = moment(timestamp).local();

  const TODAY = moment().startOf("day");
  const YESTERDAY = moment().subtract(1, "days").startOf("day");
  const isToday = TODAY.isSame(timestamp, "day");
  const isYesterday = YESTERDAY.isSame(timestamp, "day");

  if (isToday) time = timestamp.format("hh:mm A");
  else if (isYesterday) time = "Yesterday";
  else time = timestamp.format("D MMMM");

  return time;
};

// Function to get the sync date
const getUpdatedDate = (time) => {
  const syncDate = moment(time);
  var NOW = moment().startOf("day");
  var YESTERDAY = NOW.clone().subtract(1, "days").startOf("day");

  if (syncDate.isSame(NOW, "d")) return syncDate.format("hh:mm A");
  else if (syncDate.isSame(YESTERDAY, "d")) return "yesterday";
  else return syncDate.format("Do MMM");
};

export { getLastMessageTime, getUpdatedDate };
