<template>
  <div class="all-users-table">
    <!--START: Documents List-->
    <ul class="grid-table">
      <li
        class="grid-row grid-header"
        :class="{ 'grid-header-shadow': showOptions.headerShadow }"
      >
        <span class="grid-column">Name</span>
        <span class="grid-column">Email</span>
        <span class="grid-column">Role</span>
      </li>
    </ul>
    <ul
      class="grid-table grid-item-clickable"
      ref="table"
      @scroll="tableScroll"
    >
      <li v-for="(user, index) in users" :key="index" class="grid-row">
        <div class="grid-column">
          <h3 class="name">{{ user.name }}</h3>
        </div>

        <div class="grid-column">
          <span class="email">{{ user.email }}</span>
        </div>

        <div class="grid-column">
          <span class="role" :class="{ agent: user.role === 'agent' }">{{
            user.role
          }}</span>
        </div>

        <div v-if="user.role !== 'admin'" class="grid-column grid-actions">
          <button class="btn btn-icon" @click.stop="editUser(user)">
            <unicon name="pen"></unicon>
          </button>
          <button
            class="btn btn-icon btn-delete"
            @click.stop="showDeleteModal(user._id, user.name)"
          >
            <unicon name="trash-alt"></unicon>
          </button>
        </div>
      </li>
    </ul>
    <!--END: Documents List-->

    <!--START: Documents Count-->
    <span class="grid-count"
      >Showing {{ users.length }}
      {{ users.length == 1 ? "user" : "users" }}</span
    >
    <!--END: Documents Count-->

    <!--START: Confirmation Modal-->
    <ConfirmationModal
      :fields="confirmationFields"
      @primaryEvent="deleteItem"
      @secondaryEvent="closeModal"
    ></ConfirmationModal>

    <div
      class="bg-overlay light"
      :class="{ show: confirmationFields.show }"
      @click="closeModal"
    ></div>
    <!--END: Confirmation Modal-->
  </div>
</template>
      
<script>
// Importing Components
import ConfirmationModal from "@/components/modals/ConfirmationModal";

export default {
  name: "UserAccessTable",
  props: {
    users: Array,
  },
  data() {
    return {
      showOptions: {
        lineLoader: false,
        headerShadow: false,
      },
      deleteID: null,
      confirmationFields: {
        show: false,
        title: "",
        type: "delete",
        modalType: "fixed",
        message:
          "This action cannot be undone. This user will be removed from your organization",
        iconType: "exclamation",
        confirmAction: true,
        confirmValue: null,
        primaryBtn: {
          title: "Yes. Delete",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
    };
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  components: {
    ConfirmationModal,
  },
  methods: {
    // Event on table scroll
    tableScroll() {
      const scrollTop = this.$refs.table.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    // Show the delete modal
    showDeleteModal(itemID, itemTitle) {
      this.deleteID = itemID;
      this.confirmationFields.confirmValue = itemTitle;
      this.confirmationFields.show = true;
      this.confirmationFields.title = `Delete ${itemTitle}?`;
    },

    // Delete the document
    async deleteItem() {
      this.showOptions.lineLoader = true;

      this.$emit("deleteUser", this.deleteID);
      this.closeModal();
    },

    // Edit a user
    editUser(user) {
      this.$emit("editUser", user);
    },

    // Close all the modals
    closeModal() {
      this.confirmationFields.show = false;
    },
  },
};
</script>
      
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.all-users-table {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
  margin-bottom: 0.5rem;
}

.grid-table.grid-item-clickable {
  flex: 1;
  overflow-y: scroll;
}
.grid-column {
  &:nth-child(1) {
    width: calc(100% - (12.5rem) * 3 - 6rem);
  }

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    margin: 0 1rem;
    text-align: center;
    width: 12.5rem;
  }

  &:nth-child(2) {
    text-align: left;
  }
}

.name {
  font-size: $normalFontSize;
  font-weight: $mediumFontWeight;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
}

.role {
  position: relative;
  font-size: $smallestFontSize;
  font-weight: $mediumFontWeight;
  color: $greenMessageTextColor;
  background-color: lighten($greenMessageColor, 0%);
  border: 1px solid $greenMessageBorderColor;
  padding: 0.25rem 0.75rem;
  border-radius: 0.5rem;
  text-transform: capitalize;

  &.agent {
    color: $greyMessageTextColor;
    background-color: lighten($greyMessageColor, 5%);
    border: 1px solid $greyMessageBorderColor;
  }
}

.email {
  font-size: $smallerFontSize;
  color: $greyColor;
}

.btn-view {
  padding: 0;

  /deep/ svg {
    width: 1.5rem;
    height: auto;
  }

  &:hover {
    background: transparent;
    /deep/ svg {
      fill: $greyColor;
    }
  }
}

/deep/ .line-loader {
  position: relative !important;
  border-radius: 0.5rem;
}

.table-actions-wrapper {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 0.5rem;

  .document-count {
    flex: 1;
    font-weight: $mediumFontWeight;
    color: $paragraphColor;
    font-size: $smallFontSize;
    opacity: $lightOpacity;
  }

  .search-wrapper {
    position: relative;
    margin-right: 2rem;

    .unicon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1ch;
      z-index: 2;

      /deep/ svg {
        fill: $greyColor;
        opacity: $lightOpacity;
        width: 1rem;
      }
    }

    /deep/ {
      .field-main-item {
        max-width: 18rem;
      }
      input {
        padding-left: 2rem;
      }
    }
  }

  /deep/ {
    .field-main-item {
      margin-bottom: 0;

      .field-radio-wrapper {
        margin-bottom: 0;
      }
    }
  }
}
</style>