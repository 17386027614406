<template>
  <div class="onboarding-wrapper">
    <!--START: Onboarding Components-->
    <img src="@/assets/images/logo.png" class="logo" alt="Macha Logo" />
    <img
      src="@/assets/images/doodles/onboarding.webp"
      alt="Onboarding animation"
      class="onboarding-animation"
    />
    <div class="onboarding-info">
      <h3><b>Just a moment!</b>We're getting things ready</h3>
      <span>{{ onboardingStatus[onboardingStep] }}</span>
    </div>
    <!--END: Onboarding Components-->
  </div>
</template>
  
  <script>
export default {
  name: "AdminOnboarding",
  data() {
    return {
      onboardingCompleted: false,
      demoContentCreated: false,
      onboardingStep: 0,
      onboardingStatus: [
        "Creating services...",
        "Adding intelligence...",
        "Saving configuration...",
        "Almost there...",
      ],
    };
  },
  watch: {
    onboardingCompleted: async function () {
      if (this.onboardingCompleted && this.demoContentCreated) {
        await this.getUser();
        this.redirectDashboard();
      }
    },
    demoContentCreated: async function () {
      if (this.onboardingCompleted && this.demoContentCreated) {
        await this.getUser();
        this.redirectDashboard();
      }
    },
  },
  components: {},

  async created() {
    if (!(await this.authenticateUser())) this.showComponent = true;

    this.resetData();
    this.showOnboardingMessages();
    await this.createDemoContent();
  },
  methods: {
    resetData() {
      this.onboardingCompleted = false;
      this.demoContentCreated = false;
      this.onboardingStep = 0;
    },

    async createDemoContent() {
      this.demoContentCreated = true;
    },

    showOnboardingMessages() {
      const messageInterval = setInterval(
        function () {
          if (this.onboardingStatus.length > this.onboardingStep + 1)
            this.onboardingStep++;
          else {
            this.onboardingCompleted = true;
            clearInterval(messageInterval);
          }
        }.bind(this),
        1000
      );
    },
  },
};
</script>
  
  <style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.onboarding-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: $whiteColor;

  .logo {
    display: block;
    width: 8rem;
    margin: 3rem auto 3rem;
  }
  .onboarding-animation {
    display: block;
    margin: auto;
    width: 17.5rem;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: $largerFontSize;
    font-weight: $normalFontWeight;
    margin-bottom: 1rem;

    b {
      font-size: $extraLargeFontSize;
      font-family: $displayFont;
      display: block;
    }
  }

  span {
    font-size: $mediumFontSize;
    font-weight: $mediumFontWeight;
    color: $brandColor;
  }
}
</style>