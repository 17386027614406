<template>
  <div class="field-toggle-item" :class="[{ error: field.hasError }, field.additionalClass]">
    <div class="field-toggle-button">
      <input
        type="checkbox"
        :value="field.value"
        v-model="field.value"
        @click="fieldToggled"
        @change="formChanged"
      />
      <span></span>
    </div>
    <label>{{ field.value ? field.activeText : field.inactiveText }}</label>
  </div>
</template>

<script>
export default {
  name: "ToggleButton",
  props: {
    field: {
      type: Object,
      required: true,
    },
    toggleName: {
      type: String,
      required: true,
    },
  },
  methods: {
    formChanged() {
      this.$emit("formChanged");
      this.$emit("fieldChanged");
    },
    fieldToggled(e) {
      if (
        this.field.readOnly != undefined &&
        this.field.readOnly == true
      )
        e.preventDefault();
    },
  },
};
</script>

<style lang="scss">
.field-toggle-item {
  display: flex;
  flex-direction: row;
  position: relative;
  .field-toggle-button {
    position: relative;
    align-self: center;
    width: 2.5rem;
    height: 1.5rem;
  }
  label {
    color: $greyColor;
    font-size: $smallFontSize;
    align-self: center;
    line-height: 1.4;
    width: calc(100% - 3rem);
    flex: 1;
  }
  input {
    position: relative;
    cursor: pointer;
    padding: 0;
    border: none;
    z-index: 10;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
  .field-toggle-button span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0.5rem;
    background-color: darken($lightWhiteColor, 15%);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .field-toggle-button span::before {
    @include transition;
    position: absolute;
    content: "";
    height: 1.15rem;
    width: 1.15rem;
    top: 50%;
    transform: translate(0.25rem, -50%);
    left: 0;
    border-radius: 50%;
    background-color: white;
  }
  input:checked + span {
    background-color: $toggleActiveColor;
  }
  input:checked + span::before {
    transform: translate(calc(2.5rem - 1.45rem), -50%);
  }
}
</style>