<template>
  <div class="card category-insights">
    <div class="category-title-wrapper">
      <h3 class="category-title">{{ categoryTitle }}</h3>
      <span class="total-count">{{ totalCount }} total {{ unit }}</span>
    </div>
    <div v-if="categories.length > 0" class="category-item-wrapper">
      <div v-for="item in categories" :key="item.id" class="category-item">
        <span class="name">{{ item.name }}</span>
        <span class="count">{{ item.count }} {{ unit }}</span>
        <span class="percentage">{{ item.percentage }}%</span>
      </div>
    </div>

    <span v-else class="empty-catgories"
      ><unicon name="comment-alt"></unicon>No {{ unit }} in this category</span
    >
  </div>
</template>
  
<script>
// Imoprt libraries
import _ from "lodash";

export default {
  name: "CategoryInsights",
  props: {
    category: { type: Object, required: true },
    categoryTitle: { type: String, required: true },
    unit: { type: String, required: false },
  },
  data() {
    return {};
  },
  components: {},
  computed: {
    // Get the total count
    totalCount() {
      let totalCount = 0;
      this.category.agents?.forEach((agent) => {
        agent.monthlyAnalytics.forEach((month) => {
          totalCount += month.count;
        });
      });

      return totalCount;
    },

    // Create categories according to thcategory object
    categories() {
      const categories = [];

      // Iterate and construct the categories object
      this.category.agents?.forEach((agent) => {
        let count = 0;
        agent.monthlyAnalytics?.forEach((month) => {
          count += month.count;
        });

        categories.push({
          name: agent.name,
          count: count,
          percentage: Math.trunc((count / this.totalCount) * 100),
        });
      });

      return _.orderBy(categories, "count", "desc");
    },
  },
  async created() {},
  methods: {},
};
</script>
    
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.category-insights.card {
  margin: 0 0 2rem;
  padding: 0.75rem 1.25rem;
  box-shadow: none;
  background-color: darken($whiteColor, 0.5%);
  border-color: $greyBorderColor2;
}

.category-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $greyBorderColor2;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;

  h3 {
    flex: 1;
    color: $paragraphColor;
    font-size: $smallestFontSize;
    font-weight: $mediumFontWeight;
    opacity: $lightOpacity;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
  }

  .total-count {
    color: $secondaryColor;
    font-size: $smallerFontSize;
    font-weight: $mediumFontWeight;
  }
}

.category-item {
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    font-size: $smallFontSize;
  }
  .name {
    flex: 1;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: capitalize;
  }
  .count {
    flex: 1;
    text-align: right;
    margin-right: 2rem;
    opacity: $lightOpacity;
    font-weight: $mediumFontWeight;
  }
  .percentage {
    flex: 1;
    text-align: right;
    opacity: $lightOpacity;
  }
}

.empty-catgories {
  display: block;
  text-align: center;
  color: $paragraphColor;
  opacity: $lightOpacity;
  font-weight: $mediumFontWeight;
  font-size: $smallerFontSize;

  .unicon /deep/ {
    svg {
      display: inline-block;
      vertical-align: middle;
      height: auto;
      opacity: 0.5;
      width: 0.85rem;
      margin-right: 0.5rem;
    }
  }
}
</style>