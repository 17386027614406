var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rel-wrapper"},[_c('div',{staticClass:"file-upload-large",on:{"dragover":_vm.dragover,"dragleave":_vm.dragleave,"drop":_vm.drop}},[(
        _vm.field.showPreview != undefined &&
        _vm.field.showPreview == true &&
        _vm.field.fileList.length > 0
      )?_c('div',{staticClass:"image-preview"},_vm._l((_vm.field.imagesList),function(image,index){return _c('div',{key:image.id,staticClass:"image-preview-wrapper",on:{"click":function($event){return _vm.remove(index)}}},[_c('img',{attrs:{"src":image}}),_c('unicon',{attrs:{"name":"times"}})],1)}),0):_vm._e(),(
        this.field.fileList.length &&
        (_vm.field.showPreview == undefined || _vm.field.showPreview == false)
      )?_c('div',{staticClass:"files-list"},[_c('p',{staticClass:"files-list-label"},[_vm._v("Uploaded Files")]),_vm._l((_vm.field.fileList),function(file){return _c('span',{key:file.id,staticClass:"file-item",on:{"click":function($event){_vm.remove(_vm.field.fileList.indexOf(file))}}},[_c('div',{staticClass:"file-item-tag"},[_c('span',[_vm._v(_vm._s(file.name))]),_c('unicon',{attrs:{"name":"times"}})],1)])})],2):_vm._e(),(
        (_vm.field.persistUploader != undefined &&
          _vm.field.persistUploader == true) ||
        _vm.field.single == undefined ||
        _vm.field.single == false ||
        this.field.fileList.length == 0
      )?_c('input',{ref:"file",class:{ error: _vm.field.hasError },attrs:{"type":"file","multiple":_vm.field.single == true ? false : true,"name":"fields[assetsFieldHandle][]","id":_vm.id,"required":_vm.field.required,"accept":_vm.fileTypeRepo[_vm.field.fileType].accept},on:{"change":_vm.onChange,"input":_vm.fileUploaded}}):_vm._e(),(
        (_vm.field.persistUploader != undefined &&
          _vm.field.persistUploader == true) ||
        this.field.fileList.length == 0
      )?_c('label',{attrs:{"for":_vm.id}},[_vm._m(0),(_vm.field.fileInstruction != undefined)?_c('span',{staticClass:"file-instructions"},[_vm._v(_vm._s(_vm.field.fileInstruction))]):_vm._e(),_c('span',{staticClass:"files-direction"},[_vm._v(_vm._s(_vm.fileTypeRepo[_vm.field.fileType].label))])]):(
        (_vm.field.persistUploader != undefined &&
          _vm.field.persistUploader == true) ||
        (this.field.fileList.length > 0 &&
          (_vm.field.single == undefined || _vm.field.single == false))
      )?_c('label',{key:_vm.id,staticClass:"btn btn-text",attrs:{"for":_vm.id}},[_c('unicon',{attrs:{"name":"plus"}}),_c('span',[_vm._v("Add More")])],1):_vm._e()]),_vm._m(1),_c('StatusMessage',{attrs:{"show":_vm.showErrors,"isSuccess":false,"errorMessage":_vm.errorMessage}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Drop your files here or "),_c('span',{staticClass:"file-browser-link"},[_vm._v("browse")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"file-compressor"},[_c('span',[_vm._v("You can compress your files for free "),_c('a',{attrs:{"href":"https://www.compress2go.com/","target":"_blank"}},[_vm._v("here")])])])
}]

export { render, staticRenderFns }