<template>
  <div>
    <!--START: Full Name-->
    <div class="field-main-item">
      <div class="field-wrapper">
        <label class="field-title">Full Name</label>
        <div class="field-item">
          <input
            class="form-control"
            placeholder="Name which appears on your card "
            type="text"
          />
        </div>
      </div>
    </div>
    <!--END: Full Name-->

    <!--START: Card Number-->
    <div class="field-main-item">
      <div class="field-wrapper card-number-wrapper">
        <label class="field-title">Card number</label>
        <div class="field-item">
          <input
            class="form-control"
            :data-error="cardErrors.cardNumber ? true : false"
            v-model="cardNumber"
            type="tel"
            placeholder="• • • •   • • • •   • • • •   • • • •"
            v-cardformat:formatCardNumber
          />
        </div>
        <img v-if="hasBrand" :src="creditCardIcon" alt="Bank" />
      </div>
    </div>
    <!--END: Card Number-->

    <div class="field-group-wrapper">
      <!--START: Expiry Date-->
      <div class="field-main-item">
        <div class="field-wrapper">
          <label class="field-title">Expiry date</label>
          <div class="field-item">
            <input
              class="form-control"
              :data-error="cardErrors.cardExpiry ? true : false"
              v-model="cardExpiry"
              maxlength="10"
              placeholder="MM/YY"
              v-cardformat:formatCardExpiry
            />
          </div>
        </div>
      </div>
      <!--END: Expiry Date-->

      <!--START: CVC-->
      <div class="field-main-item">
        <div class="field-wrapper">
          <label class="field-title">CVC</label>
          <div class="field-item">
            <input
              class="form-control"
              :data-error="cardErrors.cardCvc ? true : false"
              v-model="cardCvc"
              placeholder="• • •"
              v-cardformat:formatCardCVC
            />
          </div>
        </div>
      </div>
      <!--END: CVC-->
    </div>
  </div>
</template>
    
<script>
// Import libraries
import Vue from "vue";
import VueCardFormat from "vue-credit-card-validation";
Vue.use(VueCardFormat);

export default {
  name: "CreditCardForm",
  data() {
    return {
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      cardPostal: null,
      cardErrors: {},
      // declaring card-brand in data{} enables card brand name/classes.
      cardBrand: null,
    };
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  computed: {
    // Check if the credit card is a branded one
    hasBrand() {
      if (this.cardBrand && this.cardBrand !== "unknown") return true;
      else return false;
    },

    // Return the image icon of the card
    creditCardIcon() {
      return require(`@/assets/images/icons/credit-card/${this.cardBrand}.png`);
    },
  },
  methods: {
    fieldChanged() {
      this.$emit("fieldChanged");
    },
  },
};
</script>
    
<style scoped lang="scss">
.card-number-wrapper {
  position: relative;

  img {
    position: absolute;
    bottom: 0.85rem;
    right: 1rem;
    width: 2rem;
  }
}
</style>