<template>
  <div v-if="show">
    <Transition name="modal">
      <!--START: Unsaved Changes Modal-->
      <div v-if="show" class="modal card confirmation-modal show">
        <div class="success-form">
          <div class="success-info-wrapper">
            <h3>{{ title }}</h3>
            <p>{{ message }}</p>
            <div class="success-btn-wrapper">
              <button
                type="button"
                class="btn btn-primary btn-small"
                @click="primaryEventHandler"
              >
                {{ primaryBtn.title }}
              </button>
              <button
                type="button"
                class="btn btn-border btn-small"
                @click="secondaryEventHandler"
              >
                {{ secondaryBtn.title }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--END: Unsaved Changes Modal-->
    </Transition>

    <!--START: Background-->
    <div class="bg-overlay light show" @click="secondaryEventHandler"></div>
    <!--END: Background-->
  </div>
</template>
  
<script>
export default {
  name: "UnsavedChangesModal",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: "You Have Unsaved Changes",
      message: "Your changes will be lost. Are you sure you want to continue?",
      type: "delete",
      primaryBtn: {
        title: "Yes, Continue",
      },
      secondaryBtn: {
        title: "Cancel",
      },
    };
  },
  methods: {
    primaryEventHandler() {
      this.$emit("primaryEvent");
    },
    secondaryEventHandler() {
      this.$emit("secondaryEvent");
    },
  },
};
</script>
  
  <style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.confirmation-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  margin: auto;
  max-width: 20rem;
  text-align: center;
  z-index: 101;
}
.success-form {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.success-info-wrapper {
  h3 {
    font-size: $largeFontSize;
  }
  p {
    color: $greyColor;
  }
}

.success-btn-wrapper {
  margin: 1rem 0 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  white-space: nowrap;
  justify-content: center;
  .btn {
    margin-right: 0.75rem;
  }
}

@media only screen and (max-width: $mobileWidth) {
  .modal {
    width: calc(100% - 5rem);
    bottom: 0.5rem;
    top: auto;
    left: 0.5rem;
    height: auto;
    transform: translateY(100vh);

    &.show {
      transform: translateY(0);
    }
  }
}
</style>