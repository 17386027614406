<template>
    <div class="authentication-component">
      <div class="authentication-wrapper">
        <div class="card">
          <!--START: Intro-->
          <div class="intro-wrapper">
            <h1>Logging Out</h1>
          </div>
          <!--END: Intro-->
  
          <!--START: Line Loader-->
          <LineLoader :show="true" additionalClass="bottom"></LineLoader>
          <!--END: Line Loader-->
        </div>
      </div>
    </div>
  </template>
    
  <script>
  // Importing components
  import LineLoader from "@/components/loaders/LineLoader";
  
  export default {
    name: "logoutUser",
    data() {
      return {};
    },
    watch: {},
    components: {
      LineLoader,
    },
    async created() {
      await this.logoutUser();
    },
    methods: {},
  };
  </script>
    
  <style scoped lang="scss">
  @import "@/assets/styles/shared/dashboard.scss";
  @import "@/assets/styles/shared/authentication.scss";
  </style>