<template>
  <div class="dashboard-view">
    <div class="page-wrapper">
      <!--START: Menu-->
      <PageMenu
        :menu="menu"
        :highlightExactRoute="false"
        class="page-menu"
      ></PageMenu>
      <!--END: Menu-->

      <!--START: Settings View-->
      <div class="page-content">
        <keep-alive><router-view></router-view></keep-alive>
      </div>
      <!--END: Settings View-->
    </div>
  </div>
</template>
  
<script>
//Importing components
import PageMenu from "@/components/dashboard/PageMenu";

export default {
  name: "Settings",
  data() {
    return {
      menu: [
        {
          name: "Settings",
          type: "category",
          menuItems: [
          {
              name: "Company Details",
              icon: "user",
              path: "/settings/company-details",
            },
            {
              name: "User Access",
              icon: "user",
              path: "/settings/user-access",
            },
            {
              name: "Integrations",
              icon: "plug",
              path: "/settings/integrations",
            },
            {
              name: "Subscriptions",
              icon: "puzzle-piece",
              path: "/settings/subscriptions",
            },
          ],
        },
      ],
    };
  },
  components: {
    PageMenu,
  },
  computed: {},
  async created() {
    this.restrictAdminAccess();
  },
  methods: {},
};
</script>
  
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

/deep/ .page-header {
  margin-bottom: 3rem;
}
</style>