<template>
  <div class="rel-wrapper">
    <!--START: Image Preview-->
    <div v-if="previewImage" class="image-preview-wrapper">
      <label :for="id"><img :src="previewImage" /></label>
    </div>
    <!--END: Image Preview-->

    <!--START: Profile Image Uploader-->
    <div class="upload-image-input">
      <input
        type="file"
        multiple="false"
        :name="id"
        :id="id"
        ref="file"
        :class="{ error: field.hasError }"
        :required="field.required"
        :accept="fileTypeRepo.image.accept"
        style="display: none"
        @change="fileUploaded"
        @input="fileUploaded"
      />

      <!--START: Upload Button-->
      <label :for="id" class="image-upload-btn" v-if="!previewImage">
        <unicon name="image-plus"></unicon>
      </label>
      <!--END: Upload Button-->

      <!--START: Profile Image Buttons-->
      <div class="profile-image-controllers">
        <label :for="id" class="btn btn-small btn-grey">
          {{ previewImage ? "Change Image" : "Upload Image" }}
        </label>
        <button
          class="btn btn-small btn-delete"
          v-if="previewImage"
          @click="removeImage()"
        >
          Delete
        </button>
      </div>
      <!--END: Profile Image Buttons-->
    </div>
    <!--END: Profile Image Uploader-->

    <!--START: Page Loader -->
    <ImageCropper
      v-if="showOptions.imageCropper"
      :show="true"
      :imageURL="imageCropperURL"
      :cropType="['square']"
      additionalClass="profile-image-cropper"
      class="image-cropper-module"
      @closeImageCropper="toggleImageCropper(false)"
      @saveImage="saveCroppedImage"
    ></ImageCropper>
    <!--END: Page Loader -->
  </div>
</template>

<script>
//Importing Components
import ImageCropper from "@/components/form/ImageCropper";

export default {
  name: "FileUploadLarge",
  delimiters: ["${", "}"],
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  components: {
    ImageCropper,
  },
  data() {
    return {
      id: null,
      showOptions: {
        imageCropper: false,
      },
      showErrors: false,
      imageCropperURL: null,
      croppedImage: { blob: null, url: null },
      errorMessage: "Some of your files are larger than 10MB",
      fileTypeRepo: {
        image: {
          label: "Supports JPGs, JPEGs, PNGs. Max total of 6MB",
          accept: ".jpg, .jpeg, .png",
        },
      },
    };
  },
  computed: {
    // Return the cropped image or the field image
    previewImage: function () {
      if (
        this.croppedImage.url != null ||
        (this.field.value !== "" && this.field.value != null)
      )
        return this.croppedImage.url ? this.croppedImage.url : this.field.value;
      return false;
    },
  },
  mounted() {
    this.id = this._uid;
  },
  methods: {
    // Event when file has been uploaded
    fileUploaded(e) {
      const files = e.target.files;

      // Check if there is a file and upload
      if (files.length && this.underFileSize(files[0])) {
        this.imageCropperURL = URL.createObjectURL(files[0]);
        this.field.fileList = [files[0]];
        this.field.imagesList = [this.imageCropperURL];
        this.toggleImageCropper(true);
      }
    },

    // Save cropped image
    saveCroppedImage(blob, url) {
      this.croppedImage = { blob, url };
      this.toggleImageCropper(false);

      this.$emit("fileUploaded", this.field.imagesList);
    },

    // Toggle image cropper modal
    toggleImageCropper(status) {
      this.showOptions.imageCropper = status;
    },

    // Remove image
    removeImage() {
      this.field.fileList.splice(0);
      this.field.imagesList.splice(0);
      this.field.value = "";

      this.imageCropperURL = null;
      this.croppedImage = { blob: null, url: null };

      this.$emit("fileUploaded", []);
    },

    // Make sure file size is below limit
    underFileSize(file) {
      let fileLimit = this.field.sizeLimit;
      if (fileLimit == undefined) return true;
      else if (file.size > fileLimit) return false;
      else return true;
    },
  },
};
</script>

<style scoped lang="scss">
[v-cloak] {
  display: none;
}

.status-message {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: -3rem !important;
  margin: auto;
}

.image-upload-btn {
  color: $greyColor;
  cursor: pointer;
  display: block;
  padding: 0.5rem 0.75rem;
  background-color: $whiteColor;
  border-radius: 0.75rem;
  border: 1px solid $inputBorderColor;

  .unicon /deep/ {
    svg {
      fill: $secondaryColor;
      display: block;
      width: 1.5rem;
      height: auto;
      margin: auto;
    }
  }

  &:hover {
    @include transition;
    border-color: $secondaryColor;
    box-shadow: 0 0 0.7rem -0.1rem $boxShadowColor;
  }
}

.profile-image-controllers {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1rem;
}

.file-upload-large {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  .file-browser-link {
    color: $blueColor;
    font-weight: $mediumFontWeight;
  }

  .files-direction {
    display: block;
    font-size: $smallestFontSize;
    margin-top: 0.5rem;
  }

  .error + label {
    border-color: $redColor;
  }
}

.image-preview-wrapper {
  position: relative;
  display: inline-block;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 0.5rem;
  padding: 0.25rem;
  margin: 0 1.5rem 0 0;
  border: 1px solid $inputBorderColor;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    border-radius: 0.35rem;
    cursor: pointer;
  }

  &:hover {
    border-color: $secondaryColor;
  }
}

.file-compressor {
  margin-top: 0.75rem;
  font-size: $smallFontSize;
  color: $greyColor;
}
</style>