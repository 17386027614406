// Importing moment.js for time based functions
import moment from "moment-timezone";

// Function to get the sync date
const getUpdatedDate = (time) => {
  const syncDate = moment(time);
  var NOW = moment().startOf("day");
  var YESTERDAY = NOW.clone()
    .subtract(1, "days")
    .startOf("day");

  if (syncDate.isSame(NOW, "d")) return syncDate.format("hh:mm A");
  else if (syncDate.isSame(YESTERDAY, "d")) return "yesterday";
  else return syncDate.format("Do MMM");
};

export { getUpdatedDate };
